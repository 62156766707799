import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import api from "../../../../api/api";
import { errorHandler } from "../../../../utils/helper";
import { successNotification } from "../../../../components/UI/Toast/Toast";
import Loader from "../../../../components/Loader/Loader";
import Breadcrumbs from "../../../../components/header/Breadcrumbs";


const PartnerOffersAndPlans: React.FC = () => {
  const [details, setDetails] = useState<undefined | any>(undefined),
    [loading, setLoading] = useState<boolean>(true),
    [data, setData] = useState<undefined | any>(undefined),
    { id } = useParams(),
    getOffersAndPlans = () => {
      api.partner_management.getPartnerOffersPlans({
        partner_id: details?.uuid
      })
        .then((res) => {
          setData(res?.data)
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
        })
    },
    getDetails = () => {
      api.partners
        .getPartnerDetails(id)
        .then((response) => {
          setDetails({
            uuid: response?.data?.uuid,
            brand_name: response?.data?.brand_name
          });
        })
        .catch((error) => {
          errorHandler(error?.response?.data)
        });
    },
    submit = (payload: any) => {
      api.partner_management.postPartnerOffersPlans({...payload, partner_id: details?.uuid})
        .then((res) => {
          getOffersAndPlans()
          successNotification("Plans and offers updated")
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
    },
    columns = [
      {
        title: "Offer and Plan details",
        key: 'plan_name',
        dataIndex: 'plan_name',
        render: (value: any, record: any) => {
          return record?.plan_name || record?.offer_name
        }
      },
      {
        title: "Provider",
        key: 'provider_name',
        dataIndex: 'provider_name',
      },
      {
        title: "Product Type",
        key: 'product_name',
        dataIndex: 'product_name',
      },
      {
        title: "Validity",
        key: 'validity',
        dataIndex: 'validity',
      },
      // {
      //   title: "Coverage Type",
      //   key: 'coverage_type',
      //   dataIndex: 'coverage_type',
      // }
    ],
    routes = [
      {
        path: '/partners/:id',
        props: { value: details?.brand_name },
      },
    ];

  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    getDetails()
  }, [])

  useEffect(() => {
    if (details) {
      getOffersAndPlans()
    }
  }, [details])

  return <Loader loading={loading}>
    <Breadcrumbs routes={routes} />
    <Outlet context={{
      details: details,
      data: data,
      submit: submit,
      fieldsToShow: {
        plans: ['Offer Price', 'Partner Selling Price', 'Slash Price'],
        offers: []
      },
      fieldsToDisable: {
        plans: [],
        offers: []
      },
      backNavigationLink: `/partners/${id}/offers-and-plans`,
      columns: columns
    }} />
  </Loader>
}

export default PartnerOffersAndPlans;
