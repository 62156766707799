import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import api from "../../../../../api/api";
import { errorHandler } from "../../../../../utils/helper";
import Breadcrumbs from "../../../../../components/header/Breadcrumbs";
import { successNotification } from "../../../../../components/UI/Toast/Toast";
import Loader from "../../../../../components/Loader/Loader";

export type OffersAndPlansContextType = {
  details: any,
  data: any,
  submit: any,
  fieldsToShow: any,
  backNavigationLink: any,
  columns: any,
  fieldsToDisable: any
}

const OffersAndPlans: React.FC = () => {
  const [details, setDetails] = useState<undefined | any>(undefined),
    [loading, setLoading] = useState<boolean>(true),
    [data, setData] = useState<undefined | any>(undefined),
    { id } = useParams(),
    getOffersAndPlans = () => {
      api.clientaddition.getOrganisationOffersAndPlans({
        organisation_id: details?.uuid
      })
        .then((res) => {
          setData(res?.data)
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
        })
    },
    getDetails = () => {
      api.clientaddition
        .getClientDetails(id)
        .then((response) => {
          setDetails({
            uuid: response.data?.uuid,
            brand_name: response.data?.brand_name,
          });
        })
        .catch((error) => {
          errorHandler(error.response?.data);
        });
    },
    submit = (payload: any) => {
      api.clientaddition.postOrganisationOffersAndPlans({...payload, organisation_id: details?.uuid})
        .then((res) => {
          getOffersAndPlans()
          successNotification("Plans and offers updated")
        })
        .catch((err) => { errorHandler(err?.response?.data) })
    },
    columns = [
      {
        title: "Offer and Plan details",
        key: 'plan_name',
        dataIndex: 'plan_name',
        render: (value: any, record: any) => {
          return record?.plan_name || record?.offer_name
        }
      },
      {
        title: "Provider",
        key: 'provider_name',
        dataIndex: 'provider_name',
      },
      {
        title: "Cost Price",
        key: 'cost_price',
        dataIndex: 'cost_price',
      },
      {
        title: "Offer Price",
        key: 'offer_selling_price',
        dataIndex: 'offer_selling_price',
      }
    ],
    routes = [
      {
        path: '/client-manager',
        props: { overRideRedirect: '/client-manager/corporate' },
      },
      {
        path: '/client-manager/corporate/:id',
        props: { value: details?.brand_name },
      },
    ];

    useEffect(()=>{
      if (data) {
        setLoading(false)
      }
    },[data])

  useEffect(() => {
    getDetails()
  }, [])

  useEffect(() => {
    if (details) {
      getOffersAndPlans()
    }
  }, [details])

  return <Loader loading={loading}>
    <Breadcrumbs routes={routes} />
    <Outlet context={{
      details: details,
      data: data,
      submit: submit,
      fieldsToShow: {
        plans: ['Offer Price', 'Cost Price', 'Slash Price'],
        offers: []
      },
      fieldsToDisable: {
        plans: ['Cost Price'],
        offers: []
      },
      backNavigationLink: `/client-manager/corporate/${id}/offers-and-plans`,
      columns: columns
    }} />
  </Loader>
}

export default OffersAndPlans;
