import React from "react";
import { toTitleCase } from "../../utils/helper";
import TableCTAComponent from "../UI/Table/TableCTAComponent";
import { JsxElement } from "typescript";

interface Props {
  getParams: any;
  checkList: any;
  resetParams: any;
  params: any;
  data?: any;
  type: string;
  searchKey: string;
  filter?: any;
  sort?: any;
  cta?: any;
  disableSearch?: boolean;
  editPermission?: boolean;
  searchPlaceholder?: string;
}

const TableCTA: React.FC<Props> = (props) => {
  return (
    <TableCTAComponent
      params={props.params}
      getParams={props.getParams}
      resetParams={props.resetParams}
      search={{
        placeholder: props.searchPlaceholder || `${toTitleCase(props.type)} name`,
        key: props.searchKey,
      }}
      disableSearch={props.disableSearch}
      summary={{
        totalCount: props.data?.count,
        checklistCount: props?.checkList ? props.checkList?.length : null,
      }}
      filter={props.filter ? props.filter : undefined}
      sort={props.sort ? props.sort : undefined}
      cta={props.cta ? props.cta : undefined}
    />
  )
}

export default TableCTA;
