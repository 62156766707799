import { DualAxes, G2 } from "@ant-design/plots";
import { config } from "process";
import { graphColors } from "../../../utils/helper";

interface Props {
  dataA: any;
  dataB: any;
  xField: string;
  yField: Array<string>;
  stacked?: boolean;
}

const ColumnLinePlot: React.FC<Props> = ({ dataA, dataB, xField, yField, stacked }) => {
  const { registerTheme } = G2;
  registerTheme('myTheme', {
    colors10: graphColors,
  })
  const config = {
    data: [dataA, dataB],
    theme: 'myTheme',
    xField: xField,
    yField: yField,
    legend: {
      position: 'bottom',
    } as any,
    xAxis: {
      title: {
        text: xField,
      }
    },
    yAxis: [
      {
        title: {
          text: yField[0],
        }
      },
      {
        title: {
          text: yField[1],
        }
      },
    ],
    geometryOptions: [
      {
        geometry: 'column',
        isStack: stacked || false,
        seriesField: 'type',
      },
      {
        geometry: 'line',
      },
    ],
  };
  return <DualAxes {...config} />;
};

export default ColumnLinePlot;
