import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, notification, Progress, Row, Tooltip } from 'antd';
notification.config({
  maxCount: 1,
});

export const successNotification = (title: string, description?: string) => {
  notification.success({
    message: title,
    description: description,
    placement: 'bottom',
  });
};

export const progressToast = (progress: any, unmount: any, source: any) => {
  notification.open({
    key: 'endorsement_progress',
    message: (
      <Row>
        <Col span={8}>
          <Tooltip
            placement="top"
            title="Member Upload Progress. If this looks stuck for a long time now, please try reuploading the sheet."
            arrowPointAtCenter
          >
            <ExclamationCircleOutlined />
            &nbsp;
          </Tooltip>
          {progress?.toFixed(2)}%&nbsp;
        </Col>
        <Col span={16}>
          <Progress
            className="endorsement_progress"
            showInfo={false}
            percent={progress}
            status="active"
            strokeColor="#11A387"
            trailColor="#FFFFFF20"
          />
        </Col>
      </Row>
    ),
    placement: 'bottom',
    duration: 0,
    onClose: () => unmount(source),
  });
};

export const closeNotification = (key: string) => {
  notification.close(key);
};

export const errorNotification = (title: string, description?: string) => {
  notification.error({
    message: title,
    description: description,
    placement: 'bottom',
  });
};
