import Breadcrumbs from "../../../components/header/Breadcrumbs";
import { Alert, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { Button } from "../../../components/UI/Button/Button";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { outletContext } from "./Claim";
import moment from "moment";
import React, { useEffect } from "react";
import { errorNotification, successNotification } from "../../../components/UI/Toast/Toast";
import api from "../../../api/api";
import { errorHandler } from "../../../utils/helper";
import { useSelector } from "react-redux";


const TreatmentDetails: React.FC = () => {
  const context = useOutletContext<outletContext>(),
    brokerName = useSelector((state: any) => state?.brokerconfig?.brandName),
    [form] = Form.useForm(),
    { claimid } = useParams(),
    navigate = useNavigate(),
    initialValues = {
      ...context?.claim,
      date_of_discharge: context?.claim?.date_of_discharge ? moment(context?.claim?.date_of_discharge) : undefined,
      date_of_admission: context?.claim?.date_of_admission ? moment(context?.claim?.date_of_admission) : undefined,
    },
    reformatPayload = (values: any) => {
      return {
        ...values,
        ...values?.date_of_discharge && { date_of_discharge: moment(values?.date_of_discharge)?.format('YYYY-MM-DD') },
        ...values?.date_of_admission && { date_of_admission: moment(values?.date_of_admission)?.format('YYYY-MM-DD') },
      }
    },
    updateDetails = (redirect: boolean) => {
      form.validateFields()
        .then((values) => {
          context.updateClaim(claimid, reformatPayload(values), 'Treatment details updated successfully')
          if (redirect) {
            navigate('../claim-details')
          }
        });
    },
    validateDateOfAdmission = (value: any) => {
      api.claims_management.dateOfAdmissionValidation({
        claim_id: claimid,
        date_of_admission: moment(value)?.format('YYYY-MM-DD')
      })
        .then((res) => {
          if (!res?.valid_risk_inception_date && !res?.valid_leaving_date) {
            errorNotification("Date of admission should be between risk inception date and risk end date")
            form.setFieldsValue({ date_of_admission: undefined })
            return;
          } else if (!res?.valid_risk_inception_date) {
            errorNotification("Date of admission should be after risk inception date")
            form.setFieldsValue({ date_of_admission: undefined })
            return;
          } else if (!res?.valid_leaving_date) {
            errorNotification("Date of admission should be before risk end date")
            form.setFieldsValue({ date_of_admission: undefined })
            return;
          }
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
    };

  useEffect(() => {
    return () => {
      context.setErrorMessage(null)
    }
  }, [])

  return <>
    <Breadcrumbs routes={context?.routes} />
    <div className="mt-56" >
      {context?.claim?.sync_status && context?.claim?.tpa?.additional_details?.api_available && <Alert type="warning" showIcon message="Fields marked with # are not fetched by API and have to be manually updated" />}
    </div>
    <Form initialValues={initialValues} form={form} className="form-bottom-border mt-24">
      <Row align="top" gutter={138}>
        <Col span={8}>
          <Form.Item
            className={context?.isUnmappedField('hospital_name')}
            label="Hospital Name"
            name="hospital_name"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <Input onChange={() => context.changeHandler(['hospital_name'])} placeholder="eg. St. Xaviers Medical Center" />
          </Form.Item>
          {context.errorMessage?.hospital_name && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.hospital_name[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('hospital_city')}
            label="Hospital City"
            name="hospital_city"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <Input onChange={() => context.changeHandler(['hospital_city'])} placeholder="eg. Mumbai" />
          </Form.Item>
          {context.errorMessage?.hospital_city && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.hospital_city[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('date_of_admission')}
            label="Date of Admission"
            name="date_of_admission"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <DatePicker onChange={(value) => {
              context.changeHandler(['date_of_admission']);
              validateDateOfAdmission(value)
            }} placeholder="DD / MM / YYYY" />
          </Form.Item>
          {context.errorMessage?.date_of_admission && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.date_of_admission[0]}
            </div>
          )}
          {brokerName === "Healthysure" && <React.Fragment>
            <Form.Item
              className={context?.isUnmappedField('processed_by')}
              label="Processed by"
              name="processed_by"
              validateTrigger="onBlur"
              labelCol={{ span: 24 }}
            >
              <Select
                showSearch
                onChange={() => context.changeHandler(['processed_by'])}
                placeholder="Select"
              >
                {context.choices?.processed_by_type_Choices?.map((data: any) => {
                  return (
                    <Select.Option key={data} value={data}>
                      {data}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {context.errorMessage?.processed_by && (
              <div role="alert" className="ant-form-item-explain-error error-backend">
                {context.errorMessage?.processed_by[0]}
              </div>
            )}
          </React.Fragment>}
        </Col>
        <Col span={8}>
          <Form.Item
            className={context?.isUnmappedField('treatment')}
            label="Treatment"
            name="treatment"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <Input onChange={() => context.changeHandler(['treatment'])} placeholder="eg. Brain Surgery" />
          </Form.Item>
          {context.errorMessage?.treatment && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.treatment[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('icd_code')}
            label="ICD Code"
            name="icd_code"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <Input onChange={() => context.changeHandler(['icd_code'])} placeholder="Enter 6-digit code" />
          </Form.Item>
          {context.errorMessage?.icd_code && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.icd_code[0]}
            </div>
          )}
          <Form.Item
            className={context?.isUnmappedField('date_of_discharge')}
            label="Date of discharge"
            name="date_of_discharge"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <DatePicker onChange={() => context.changeHandler(['date_of_discharge'])} placeholder="DD / MM / YYYY" disabledDate={(current: any) => current.isAfter(moment())} />
          </Form.Item>
          {context.errorMessage?.date_of_discharge && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {context.errorMessage?.date_of_discharge[0]}
            </div>
          )}
        </Col>
      </Row>
    </Form>
    <Row justify="end" gutter={16} className="mt-24">
      <Col>
        <Button type="secondary" onClick={() => navigate('..')}>Back</Button>
      </Col>
      <Col>
        <Button type="primary" onClick={() => updateDetails(false)}>Update</Button>
      </Col>
      <Col>
        <Button type="primary" onClick={() => updateDetails(true)}>Update and go to Claim Details</Button>
      </Col>
    </Row>
  </>
}

export default TreatmentDetails;
