import { Checkbox, Col, Collapse, Form, InputNumber, Row, Switch } from 'antd';
import './_product-plan-selection.scss'
import React, { useEffect, useState } from 'react';
import { Button } from '../Button/Button';


interface Props {
  data: any;
  form: any;
  fieldsToShow: Array<'Cost Price' | 'Slash Price' | 'Offer Price' | 'Partner Selling Price'>;
  fieldsToDisable: Array<'Cost Price' | 'Slash Price' | 'Offer Price' | 'Partner Selling Price'>;
  type: 'offer' | 'plan';
  index: number;
}

const ProductPlanSelection: React.FC<Props> = ({ data, form, fieldsToShow, fieldsToDisable, type, index }) => {
  const [checked, setChecked] = useState<boolean>(false),
    [showMessage, setShowMessage] = useState<boolean>(false),
    [active, setActive] = useState<boolean>(false),
    fieldMap = new Map([
      ['Cost Price', "cost_price"],
      ['Slash Price', "selling_price"],
      ['Offer Price', "offer_selling_price"],
      ['Partner Selling Price', "cost_price"],
    ]),
    changeChecked = () => {
      setChecked(form.getFieldValue(type === 'plan' ? `plans${index}` : `offers${index}`)?.find((item: any) => item?.is_active === true) ? true : false)
    },
    changeShowMessage = () => {
      let activePlan = form.getFieldValue(`plans${index}`)?.filter((item: any) => item?.is_active)?.find((item: any) => {
        return fieldsToShow.find((x: any) => !fieldsToDisable.includes(x) && !item?.[fieldMap.get(x) || ''])
      })
      if (activePlan) {
        setShowMessage(true)
      } else {
        setShowMessage(false)
      }
    },
    changeAllToggle = (val: boolean) => {
      if (type === 'plan') {
        form.setFieldsValue({
          [`plans${index}`]: form.getFieldValue(`plans${index}`)?.map((item: any) => {
            return {
              ...item,
              is_active: val
            }
          })
        })
      } else {
        form.setFieldsValue({
          [`offers${index}`]: form.getFieldValue(`offers${index}`)?.map((item: any) => {
            return {
              ...item,
              is_active: val
            }
          })
        })
      }
      changeChecked()
    };

  useEffect(() => {
    if (type === 'plan') {
      form.setFieldsValue({
        [`plans${index}`]: data?.plans
      })
    } else {
      form.setFieldsValue({
        [`offers${index}`]: data?.offers
      })
    }
  }, [data])

  useEffect(() => {
    changeShowMessage()
  }, [form.getFieldValue(`plans${index}`)])

  useEffect(() => {
    changeChecked()
  }, [data])

  return <div className="product-plan-selection">
    <Collapse
      expandIconPosition="right"
      className='plan-collapse border-less-collapse'
      activeKey={active ? `${data?.provider_name}${index}` : ''}
    >
      <Collapse.Panel
        key={`${data?.provider_name}${index}`}
        header={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
            onClick={() => setActive(!active)}
          >
            {type === 'plan' && <div>
              <span>{data?.product_name} - {data?.provider_name}</span>
              {showMessage && <span className='error-message'>(Please enter amount)</span>}
            </div>}
            {type == 'offer' && <div>{data?.provider_name}</div>}

            <div>
              <Switch
                checked={checked}
                onClick={(value, event) => {
                  event.stopPropagation()
                  setActive(true)
                  changeAllToggle(value)
                }}
              />
            </div>
          </div>
        }
      >
        <div className="heading required">Customize Plans</div>
        <Row gutter={16} align='middle' className='pseudo-table-header'>
          <Col span={1}></Col>
          <Col span={7}>
            {type === 'plan' ? "Plan Name" : "Offer Name"}
          </Col>
          {fieldsToShow.includes('Cost Price') && <Col span={5}>
            <span className="required">Cost Price</span>
          </Col>}
          {fieldsToShow.includes('Partner Selling Price') && <Col span={5}>
            <span className="required">Partner Selling Price</span>
          </Col>}
          {fieldsToShow.includes('Slash Price') && <Col span={5}>
            <span className="required">Slash Price</span>
          </Col>}
          {fieldsToShow.includes('Offer Price') && <Col span={5}>
            <span className="required">Offer Price</span>
          </Col>}
        </Row>
        <div style={{
          borderBottom: "16px solid #F5F5F5",
        }}>
          <Form.List name={type === 'plan' ? `plans${index}` : `offers${index}`}>
            {(fields) => {
              return (
                <>
                  {fields.map((field: any, i: any) => (
                    <React.Fragment key={i}>
                      <Row gutter={16} align='middle' className='pseudo-table-row'>
                        <Col span={1}>
                          <Form.Item
                            name={[i, 'is_active']}
                            valuePropName="checked"
                            style={{
                              margin: '0',
                            }}
                          >
                            <Checkbox onChange={changeChecked} />
                          </Form.Item>
                        </Col>
                        {type === 'plan' && <Col span={7}>
                          <span>{data?.plans?.[i]?.plan_name}</span>
                        </Col>}
                        {type === 'offer' && <Col span={7}>
                          <span>{data?.offers?.[i]?.offer_name}</span>
                        </Col>}
                        {fieldsToShow.includes('Cost Price') && <Col span={5}>
                          <Form.Item
                            name={[i, fieldMap.get('Cost Price')]}
                            style={{
                              margin: '6px 0',
                              maxWidth: '224px',
                              borderRadius: '4px',
                            }}
                          >
                            <InputNumber
                              onChange={changeShowMessage}
                              disabled={fieldsToDisable.includes('Cost Price')}
                              min={0}
                              size="small"
                              addonBefore="₹"
                              formatter={(value) => (value ? Math.round(parseFloat(value))?.toLocaleString('en-IN') : '')}
                              className="w-addon"
                              placeholder="Partner’s cost price"
                            />
                          </Form.Item>
                        </Col>}
                        {fieldsToShow.includes('Partner Selling Price') && <Col span={5}>
                          <Form.Item
                            name={[i, fieldMap.get('Partner Selling Price')]}
                            style={{
                              margin: '6px 0',
                              maxWidth: '224px',
                              borderRadius: '4px',
                            }}
                            rules={[
                              {
                                required: form.getFieldValue([`plans${index}`, i, 'is_active']),
                                message: 'This field is required'
                              }
                            ]}
                          >
                            <InputNumber
                              disabled={fieldsToDisable.includes('Partner Selling Price')}
                              min={0}
                              size="small"
                              addonBefore="₹"
                              formatter={(value) => (value ? Math.round(parseFloat(value))?.toLocaleString('en-IN') : '')}
                              className="w-addon"
                              placeholder=''
                            />
                          </Form.Item>
                        </Col>}
                        {fieldsToShow.includes('Slash Price') && <Col span={5}>
                          <Form.Item
                            name={[i, fieldMap.get('Slash Price')]}
                            style={{
                              margin: '6px 0',
                              maxWidth: '224px',
                              borderRadius: '4px',
                            }}
                            rules={[
                              {
                                required: form.getFieldValue([`plans${index}`, i, 'is_active']),
                                message: 'This field is required'
                              }
                            ]}
                          >
                            <InputNumber
                              disabled={fieldsToDisable.includes('Slash Price')}
                              min={0}
                              size="small"
                              addonBefore="₹"
                              formatter={(value) => (value ? Math.round(parseFloat(value))?.toLocaleString('en-IN') : '')}
                              className="w-addon"
                              placeholder='Enter amount'
                            />
                          </Form.Item>
                        </Col>}
                        {fieldsToShow.includes('Offer Price') && <Col span={5}>
                          <Form.Item
                            name={[i, fieldMap.get('Offer Price')]}
                            style={{
                              margin: '6px 0',
                              maxWidth: '224px',
                              borderRadius: '4px',
                            }}
                            rules={[
                              {
                                required: form.getFieldValue([`plans${index}`, i, 'is_active']),
                                message: 'This field is required'
                              }
                            ]}
                          >
                            <InputNumber
                              disabled={fieldsToDisable.includes('Offer Price')}
                              min={0}
                              size="small"
                              addonBefore="₹"
                              formatter={(value) => (value ? Math.round(parseFloat(value))?.toLocaleString('en-IN') : '')}
                              className="w-addon"
                              placeholder='Enter amount'
                            />
                          </Form.Item>
                        </Col>}
                      </Row>
                    </React.Fragment>
                  ))}
                </>
              )
            }}
          </Form.List>
        </div>
      </Collapse.Panel>
    </Collapse>
  </div>
}
export default ProductPlanSelection;
