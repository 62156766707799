import { Spin } from 'antd';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Claim from '../pages/Dashboard/ClaimsManager/Claim';
import MemberClaimDetails from '../pages/Dashboard/ClaimsManager/ClaimDetails';
import ClaimsAnalytics from '../pages/Dashboard/ClaimsManager/ClaimsAnalytics';
import Communication from '../pages/Dashboard/ClaimsManager/Communication';
import Documents from '../pages/Dashboard/ClaimsManager/Documents';
import TreatmentDetails from '../pages/Dashboard/ClaimsManager/TreatmentDetails';
import ViewClaim from '../pages/Dashboard/ClaimsManager/ViewClaim';
import AddTransactionDetails from '../pages/Dashboard/ClientManager/Corporate/PoliciesAndProducts/AddTransaction/AddTransactionDetails';
import PlanListing from '../pages/Dashboard/Products/Details/PlanListing';
import Settings from '../pages/Dashboard/Settings/Settings';
import lazyWithRetry from './lazyWithRetry';
import UpdateOffersAndPlans from '../pages/Dashboard/ClientManager/Corporate/OffersAndPlans/UpdateOffersAndPlans';
import ListOffersAndPlans from '../pages/Dashboard/ClientManager/Corporate/OffersAndPlans/ListOffersAndPlans';
import OffersAndPlans from '../pages/Dashboard/ClientManager/Corporate/OffersAndPlans/OffersAndPlans';
import PartnerOffersAndPlans from '../pages/Dashboard/PartnerManager/OffersAndPlans/PartnerOffersAndPlans';
import AgentOffersAndPlans from '../pages/Dashboard/AgentManager/AddAgent/AgentOffersAndPlans';
import OffersAndPlansSection from '../pages/Dashboard/OffersAndPlans/OffersAndPlansSection';
const AddUpdatePlan = lazyWithRetry(() => import('../pages/Dashboard/Products/Details/AddUpdatePlan'));
const ClaimsProcessorDocuments = lazyWithRetry(() => import('../pages/Dashboard/ClaimsManager/ClaimsProcessorDocuments'));
const Finance = lazyWithRetry(() => import('../pages/Dashboard/Finance/Finance'));
const UploadTxMIS = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/AddClient/UploadData/UploadTxMIS'));
const Transactions = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/ViewClient/Transactions/Transactions'));
const AgentUserManagement = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AddAgent/UserManagement/AgentUserManagement'));
const AgentAddUser = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AddAgent/UserManagement/User/AgentAddUser'));
const AddAgent = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AddAgent/AddAgent'));
const AgentConfigAddition = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AddAgent/AgentConfigAddition'));
const AgentConfig = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AddAgent/AgentConfig'));
const AgentFeatures = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AddAgent/AgentFeatures'));
const AgentDetails = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AddAgent/AgentDetails'));
const AgentProductsAddition = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AddAgent/AgentProductsAddition'));
const AgentProducts = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AddAgent/AgentProducts'));
const ProductListing = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/Products/ProductListing'));
const AgentManager = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AgentManager'));
const ViewAgent = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/ViewAgent'));
const IndividualMemberAddition = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/ViewClient/MemberManager/AddMember/Individual/IndividualMemberAddition'));
const ClaimsListing = lazyWithRetry(() => import('../pages/Dashboard/ClaimsManager/ClaimsListing'));
const AgentListing = lazyWithRetry(() => import('../pages/Dashboard/AgentManager/AgentListing'));
const ClientMemberSubs = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/PoliciesAndProducts/ClientMemberSubs'));
const ClientCustomisation = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/Customisation/ClientCustomisation'));
const CorporateClientListing = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/ClientList/ClientList'))
const IndividualClientListing = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Individual/ClientList/ClientList'))
const PartnerListing = lazyWithRetry(() => import('../pages/Dashboard/PartnerManager/PartnerList/PartnerList'))
const ClientMemberCommunication = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Corporate/ViewClient/CommunicationManager/ClientMemberCommunication')
);
const CommunicationManagement = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Corporate/ViewClient/CommunicationManager/CommunicationManagement')
);
const PartnerPoliciesAndProducts = lazyWithRetry(
  () => import('../pages/Dashboard/PartnerManager/PoliciesAndProducts/PoliciesAndProducts')
);
const ViewPartner = lazyWithRetry(() => import('../pages/Dashboard/PartnerManager/ViewPartner/ViewPartner'));
const PartnerManager = lazyWithRetry(() => import('../pages/Dashboard/PartnerManager/PartnerManager'));
const OfferManager = lazyWithRetry(() => import('../pages/Dashboard/OfferManager/OfferManager'));
const AssignOffers = lazyWithRetry(() => import('../pages/Dashboard/OfferManager/AssignOffers'));
const AddOffers = lazyWithRetry(() => import('../pages/Dashboard/OfferManager/AddOffers/AddOffers'));
const OfferList = lazyWithRetry(() => import('../pages/Dashboard/OfferManager/OfferList/OfferList'))
const OfferListingForBroker = lazyWithRetry(() => import('../pages/Dashboard/OfferManager/OfferList/ForBrokers'))
const AddIndividualClientProduct = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Individual/PoliciesAndProducts/AddIndividualClientProduct')
);
const AddIndividual = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Individual/AddIndividual/AddIndividual'));
const IndividualMemberDetails = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Individual/AddIndividual/MemberDetails')
);
const IndividualProductDetails = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Individual/AddIndividual/ProductDetails')
);
const ResetPassword = lazyWithRetry(() => import('../pages/Login/SetPassword/ResetPassword'));
const CouponManagement = lazyWithRetry(() => import('../pages/Dashboard/Products/Details/CouponManagement/CouponManagement'));
const Unauthorized = lazyWithRetry(() => import('../pages/Dashboard/Unauthorized'));
const Maintenance = lazyWithRetry(() => import('../pages/Maintenance'));
const FiveHundred = lazyWithRetry(() => import('../pages/FiveHundred'));
const SetPassword = lazyWithRetry(() => import('../pages/Login/SetPassword/SetPassword'));
const AddProducts = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/AddClient/AddProducts/AddProducts'));
const UploadData = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/AddClient/UploadData/UploadData'));
const ClientDetails = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Corporate/AddClient/ClientDetails/ClientDetails')
);
const ProductBenefits = lazyWithRetry(() => import('../pages/Dashboard/Products/Details/ProductBenefits'));
const AddProductBenefits = lazyWithRetry(() => import('../pages/Dashboard/Products/Details/AddProductBenefits'));
const TPA = lazyWithRetry(() => import('../pages/Dashboard/Products/TPA/TPA'));
const ViewClient = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/ViewClient/ViewClient'));
const ViewIndividualClient = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Individual/ViewClient/ViewClient'));
const MemberManagement = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Corporate/ViewClient/MemberManager/MemberManagement')
);
const AddMember = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Corporate/ViewClient/MemberManager/AddMember/AddMember')
);
const AddIndividualMember = lazyWithRetry(
  () =>
    import(
      '../pages/Dashboard/ClientManager/Corporate/ViewClient/MemberManager/AddMember/Individual/MemberDetails/AddIndividualMember'
    )
);
const ClientSettings = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/ViewClient/ClientFeatures/ClientSettings'));

// Protected
const Dashboard = lazyWithRetry(() => import('../pages/Dashboard/Dashboard/Dashboard'));
const CorporateClientManager = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/CorporateClientManager'));
const IndividualClientManager = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Individual/IndividualClientManager')
);
const ProviderListing = lazyWithRetry(() => import('../pages/Dashboard/Providers/ProviderListing'));
const Providers = lazyWithRetry(() => import('../pages/Dashboard/Providers/Provider'));
const Provider = lazyWithRetry(() => import('../pages/Dashboard/Providers/Provider/Provider'));
const AddProduct = lazyWithRetry(() => import('../pages/Dashboard/Providers/Provider/AddProduct'));
const AddProvider = lazyWithRetry(() => import('../pages/Dashboard/Providers/AddProvider'));
const Products = lazyWithRetry(() => import('../pages/Dashboard/Products/Details/Products'));
const ClaimsManager = lazyWithRetry(() => import('../pages/Dashboard/ClaimsManager/ClaimsManager'));
const IndividualClaimsManager = lazyWithRetry(() => import('../pages/Dashboard/ClaimsManager/IndividualClaimsManager'));
const ClaimDetails = lazyWithRetry(() => import('../pages/Dashboard/ClaimsManager/AddClaim/ClaimDetails'));
const UserManagement = lazyWithRetry(() => import('../pages/Dashboard/UserManagement/UserManagement'));
const PoliciesAndProducts = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Corporate/PoliciesAndProducts/PoliciesAndProducts')
);
const IndividualPoliciesAndProducts = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Individual/PoliciesAndProducts/PoliciesAndProducts')
);

const AddRole = lazyWithRetry(() => import('../pages/Dashboard/UserManagement/Role/AddRole'));
const AddUser = lazyWithRetry(() => import('../pages/Dashboard/UserManagement/User/AddUser'));
const AddEntity = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/AddClient/AddClient'));
const ProductDetails = lazyWithRetry(
  () =>
    import(
      '../pages/Dashboard/ClientManager/Corporate/ViewClient/MemberManager/AddMember/Individual/ProductDetails/ProductDetails'
    )
);
const ReviewMemberData = lazyWithRetry(
  () => import('../pages/Dashboard/ClientManager/Corporate/ViewClient/MemberManager/AddMember/Bulk/ReviewMemberData')
);
const ReviewScreen = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/AddClient/ReviewData/ReviewScreen'));
const ReviewTxMemberData = lazyWithRetry(() => import('../pages/Dashboard/ClientManager/Corporate/AddClient/ReviewData/ReviewTxMemberData'));

// Public
const LoginScreen = lazyWithRetry(() => import('../pages/Login/LoginScreen/LoginScreen'));
const ForgotPassword = lazyWithRetry(() => import('../pages/Login/ForgotPassword/ForgotPassword'));
const LoginViaOTP = lazyWithRetry(() => import('../pages/Login/LoginViaOTP/LoginViaOTP'));


const Router: React.FC = () => {
  const isAuthenticated = useSelector((state: any) => state.auth.isLoggedIn),
    controls = useSelector((state: any) => state.auth.controls);

  if (Object.keys(controls).length === 0) {
    return (
      <div className='loader-div' style={{ height: "200px" }}>
        <div className="loader">
          <Spin size="large"></Spin>
        </div>
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className='loader-div'>
          <div className="loader">
            <Spin size="large"></Spin>
          </div>
        </div>
      }
    >
      <Routes>
        {isAuthenticated && (
          <>
            <Route path="/" element={<Navigate to="/dashboard" />} />

            <Route path="/home" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/reports" element={<Finance />} />
            <Route path="/settings" element={<Settings />} />

            <Route path="/client-manager/corporate/*" element={<CorporateClientManager />}>
              <Route path="" element={<CorporateClientListing />} />
              <Route path=":id" element={<ViewClient />} />
              <Route path=":id/offers-and-plans" element={<OffersAndPlans />}>
                <Route path='' element={<ListOffersAndPlans />} />
                <Route path="update" element={<UpdateOffersAndPlans />} />
              </Route>
              <Route path=":id/customisation" element={<ClientCustomisation />} />
              {/* <Route path=":id/add-products" element={<AddProducts from="view-section" pageIntent="add-client" intent="add-product" existing={false} />} /> */}
              <Route path=":id/add-products" element={<AddTransactionDetails transaction_only={false} />} />
              <Route path=":id/add-members">
                <Route path="" element={<AddMember from="view-section" />} />
                <Route path="individual/*" element={<IndividualMemberAddition />}>
                  <Route path="" element={<AddIndividualMember from="view-section" />} />
                  <Route path="products" element={<ProductDetails />} />
                </Route>
                <Route path="bulk" element={<ReviewMemberData intent="add" />} />
              </Route>
              <Route path=":id/settings">
                <Route path="" element={<ClientSettings />} />
                <Route path="transactions/*">
                  <Route path="" element={<UploadTxMIS />} />
                  <Route path="review" element={<ReviewTxMemberData />} />
                  <Route path="products" element={<UploadTxMIS />} />
                </Route>
              </Route>
              <Route path=":id/member-manager" element={<MemberManagement />} />
              <Route path=":id/policies-and-products" element={<PoliciesAndProducts />} />
              <Route path=":id/policies-and-products/add-transaction-details" element={<AddTransactionDetails transaction_only={true} />} />
              <Route path=":id/communication" element={<CommunicationManagement />} />
              <Route path=":id/communication/:channel/:product_id" element={<ClientMemberCommunication />} />
              <Route path=":id/policies-and-products/:productid/benefits" element={<ProductBenefits />} />
              <Route path=":id/policies-and-products/:productid/:type/transactions" element={<Transactions />} />
              <Route path=":id/policies-and-products/:productid/subscriptions" element={<ClientMemberSubs />} />
              <Route path=":id/member-manager/add" element={<AddMember />} />
              <Route path=":id/member-manager/add/individual/*" element={<IndividualMemberAddition />}>
                <Route path="" element={<AddIndividualMember />} />
                <Route path="products" element={<ProductDetails />} />
              </Route>
              <Route path=":id/member-manager/add/bulk" element={<ReviewMemberData intent="add" />} />
              <Route path=":id/member-manager/modify" element={<UploadData intent="MODIFICATION" />} />
              <Route path=":id/member-manager/modify-endorsements" element={<UploadData type="endorsement" />} />
              <Route path=":id/member-manager/modify-endorsements/review" element={<ReviewMemberData type="endorsement" />} />
              <Route path=":id/member-manager/modify/review" element={<ReviewMemberData intent="modify" />} />
              <Route path=":id/member-manager/delete" element={<UploadData intent="DELETION" />} />
              <Route path=":id/member-manager/delete/review" element={<ReviewMemberData intent="delete" />} />
              <Route path=":id/member-manager/assign-products" element={<UploadData intent="assign" />} />
              <Route path=":id/member-manager/assign-products/review" element={<ReviewMemberData intent="assign" />} />
              <Route
                path=":id/policies-and-products/add"
                element={<AddProducts pageIntent="add-client" intent="add-product" existing />}
              />
              <Route path="add-client/*" element={<AddEntity />}>
                <Route path="" element={<ClientDetails pageIntent="add-client" />} />
                <Route
                  path=":id/products"
                  element={<AddProducts pageIntent="add-client" intent="add-client" existing={false} />}
                />
                <Route path=":id" element={<ClientDetails pageIntent="add-client" />} />
                <Route
                  path=":id/products/:saleid"
                  element={<AddProducts pageIntent="add-client" intent="add-client" existing={true} />}
                />
                <Route path=":id/products/:saleid/upload" element={<UploadData intent="ADDITION" />} />
                <Route path=":id/products/:saleid/upload/review" element={<ReviewScreen />} />
              </Route>

              <Route path=":id/claims-manager/*">
                <Route path="" element={<ClaimsListing />} />
                <Route path="add-claim" element={<ClaimsListing />} />
                <Route path="analytics" element={<ClaimsAnalytics />} />
                <Route path="view-claim/:claimid/*" element={<Claim />}>
                  <Route path="" element={<ViewClaim />} />
                  <Route path="treatment-details" element={<TreatmentDetails />} />
                  <Route path="claim-details" element={<MemberClaimDetails />} />
                  <Route path="documents" element={<Documents />} />
                  <Route path="communication" element={<Communication />} />
                </Route>
              </Route>
            </Route>

            <Route path="/client-manager/individual/*" element={<IndividualClientManager />}>
              <Route path="" element={<IndividualClientListing />} />
              <Route path="add-member/*" element={<AddIndividual />}>
                <Route path="" element={<IndividualMemberDetails />} />
                <Route path="product/:id" element={<IndividualProductDetails />} />
              </Route>
              <Route path=":id" element={<ViewIndividualClient />} />
              <Route path=":id/edit" element={<IndividualClientManager />} />
              <Route path=":id/policies-and-products/*">
                <Route path="" element={<IndividualPoliciesAndProducts />} />
                <Route path="add" element={<AddIndividualClientProduct />} />
              </Route>
              <Route path=":id/claims-manager/*">
                <Route path="" element={<IndividualClaimsManager />} />
                {/* <Route path="add-claim" element={<IndividualClaimsManager />} /> */}
                <Route path="add-claim" element={<ClaimDetails />} />
                <Route path="view-claim/:claimid/*" element={<Claim />}>
                  <Route path="" element={<ViewClaim />} />
                  <Route path="treatment-details" element={<TreatmentDetails />} />
                  <Route path="claim-details" element={<MemberClaimDetails />} />
                  <Route path="documents" element={<Documents />} />
                  <Route path="communication" element={<Communication />} />
                </Route>
              </Route>
            </Route>
            <Route path="/claims-manager/*" element={<ClaimsManager />}>
              <Route path="" element={<ClaimsListing />} />
              <Route path=":id" element={<ClaimsListing />} />
              <Route path="add-claim" element={<ClaimDetails />} />
              <Route path=":id/add-claim" element={<ClaimDetails />} />
              <Route path="view-claim/:claimid/*" element={<Claim />}>
                <Route path="" element={<ViewClaim />} />
                <Route path="treatment-details" element={<TreatmentDetails />} />
                <Route path="claim-details" element={<MemberClaimDetails />} />
                <Route path="documents" element={<Documents />} />
                <Route path="communication" element={<Communication />} />
              </Route>
            </Route>

            <Route path="/user-management/*">
              <Route path="" element={<UserManagement />} />
              <Route path="add-user" element={<AddUser />} />
              <Route path="add-role" element={<AddRole />} />
            </Route>

            <Route path="/unauthorized" element={<Unauthorized />} />

            {/* {is_admin && <> */}
            <Route path="/agent-manager/*" element={<AgentManager />}>
              <Route path="" element={<AgentListing />} />
              <Route path="add-agent/" element={<AddAgent />}>
                <Route path="" element={<AgentDetails />} />
                <Route path=":uuid" element={<AgentDetails />} />
                <Route path=":uuid/products/" element={<AgentProductsAddition />} />
                <Route path=":uuid/configuration" element={<AgentConfigAddition />} />
              </Route>
              <Route path=":uuid" element={<ViewAgent />} />
              <Route path=":uuid/manage-products" element={<AgentProducts />} />
              <Route path=":uuid/offers-and-plans" element={<AgentOffersAndPlans />}>
                <Route path='' element={<ListOffersAndPlans />} />
                <Route path="update" element={<UpdateOffersAndPlans />} />
              </Route>
              <Route path=":uuid/customisation" element={<AgentConfig />} />
              <Route path=":uuid/features" element={<AgentFeatures />} />
              <Route path=":uuid/admin-management">
                <Route path="" element={<AgentUserManagement />} />
                <Route path="add-admin" element={<AgentAddUser />} />
              </Route>
            </Route>

            <Route path="/partners/*" element={<PartnerManager />}>
              <Route path="" element={<PartnerListing />} />
              <Route path=":id" element={<ViewPartner />} />
              <Route path=":id/offers-and-plans" element={<PartnerOffersAndPlans />}>
                <Route path="" element={<ListOffersAndPlans />} />
                <Route path="update" element={<UpdateOffersAndPlans />} />
              </Route>
              <Route path=":id/policies-and-products" element={<PartnerPoliciesAndProducts />} />
              <Route path=":id/policies-and-products/add" element={<AddProducts pageIntent="add-partner" existing />} />
              <Route path="add-partner/*" element={<AddEntity />}>
                <Route path="" element={<ClientDetails pageIntent="add-partner" />} />
                <Route path=":id/products" element={<AddProducts pageIntent="add-partner" existing={false} />} />
                <Route path=":id" element={<ClientDetails pageIntent="add-partner" />} />
              </Route>
            </Route>

            <Route path="/offers-and-plans/*" element={<OffersAndPlansSection />} />
            {/* <Route path="/offers-and-plans/*" element={<ForBrokers />} /> */}

            <Route path="/offers/*" element={<OfferManager />}>
              <Route path="" element={<OfferList />} />
              <Route path=":uuid" element={<AddOffers />} />
              <Route path="add-offers" element={<AddOffers />} />
            </Route>

            <Route path="/providers/*" element={<Providers />}>
              <Route path="" element={<ProviderListing />} />
              <Route path=":providerid" element={<Provider />} />
              <Route path=":providerid/add" element={<AddProduct />} />
              <Route path="add" element={<AddProvider />} />
            </Route>

            {controls?.is_admin &&
              <Route path="/products" element={<Products />}>
                <Route path="" element={<ProductListing interactive={true} />} />
                <Route path="benefits/:productid" element={<ProductBenefits />} />
                <Route path="benefits/:productid/add" element={<AddProductBenefits />} />
                <Route path=":productid/plans" element={<PlanListing />} />
                <Route path=":productid/plans/add" element={<AddUpdatePlan />} />
                <Route path=":productid/plans/update/:uuid" element={<AddUpdatePlan />} />
                <Route path=':productid/plans/:planid' element={<CouponManagement />} />
                {/* <Route path=":providerid/coupons/:productid" element={<CouponManagement />} /> */}
              </Route>
            }

            {!controls?.is_admin &&
              <Route path="/products" element={<ProductListing interactive={false} />} />
            }

            <Route path="/tpa-Master" element={<TPA />} />

            <Route path="/claims-processor/">
              <Route path="documents/:id" element={<ClaimsProcessorDocuments />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>

            {/* <Route path="*" element={<Navigate to="/" />} /> */}
            {/* </>} */}
          </>
        )}
        {!isAuthenticated && (
          <>
            <Route path="/" element={<LoginScreen />} />
            <Route path="login" element={<Navigate to="/" />} />
            <Route path="signin" element={<Navigate to="/" />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/login-via-otp" element={<LoginViaOTP />} />
            {/* <Route path="/maintenance" element={<Maintenance />} /> */}
          </>
        )}
        <Route path="/claims-processor/">
          <Route path="documents/:id" element={<ClaimsProcessorDocuments />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/500" element={<FiveHundred />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
