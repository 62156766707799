import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import Router from '../../router/Router';
import './_claimsProcessorLayout.scss';

const ClaimsProcessorLayout: React.FC = () => {
  const dispatch = useDispatch();


  return (
    <Loader
      loading={false}
    >
      <div className="container">
        <Row justify="center">
          <Col span={24}>
            <div className="claims-processor-header">
              <div className="logo" />
            </div>
          </Col>
          <Col span={24} className='claims-processor-page' >
            <Router />
          </Col>
        </Row>
      </div>
    </Loader>
  );
};

export default ClaimsProcessorLayout;
