import { Result } from 'antd';
import React from 'react';

const Unauthorized: React.FC = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      style={{ marginTop: '200px' }}
    />
  );
};

export default Unauthorized;
