import { createSlice } from '@reduxjs/toolkit';
import { clearLocalData, getAccessToken, removeAxiosHeaders } from '../utils/helper';

const initialState = {
  isLoggedIn: getAccessToken() ? true : false,
  role: [],
  permissions: {},
  name: '',
  email: '',
  controls: {},
  notification_count: 0,
  download_reports: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
    },
    userData: (state, action) => {
      state.role = action.payload.role;
      state.permissions = action.payload.permissions;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.notification_count = action.payload.notification_count;
    },
    setControls: (state, action) => {
      state.controls = action.payload.controls;
      state.download_reports = action.payload.download_reports;
    },
    setNotification: (state, action) => {
      state.notification_count = action.payload.notification_count;
    },
    logout: (state) => {
      clearLocalData();
      removeAxiosHeaders();
      state.isLoggedIn = false;
      state.role = [];
      state.name = '';
      state.controls = {};
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
