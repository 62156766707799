import { Tooltip as TooltipUI } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface TooltipProps {
  message: string | React.ReactNode;
  color?: 'pink' | 'red' | 'yellow' | 'orange' | 'cyan' | 'green' | 'blue' | 'purple' | 'geekblue' | 'magenta' | 'volcano' | 'gold' | 'lime';
  placement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';
  style?: React.CSSProperties;
}

const Tooltip: React.FC<TooltipProps> = ({ message, color, placement, style }) => {
  return (
    <TooltipUI title={message} placement={placement} color={color}>
      <InfoCircleOutlined style={style} />
    </TooltipUI>
  )
}

export default Tooltip;
