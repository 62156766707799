import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useState } from 'react';
import './_navbar.scss';

interface Props {
  title: string;
  children: any;
  active: boolean;
}

const NavbarGroup: React.FC<Props> = ({ children, title, active }) => {
  const [show, setShow] = useState(active);

  return (
    <>
      <Row justify="space-between" className="navbar-item nav-link" onClick={() => setShow(!show)}>
        <Col>{title}</Col>
        <Col>{show ? <CaretUpOutlined /> : <CaretDownOutlined />}</Col>
      </Row>
      {show && children}
    </>
  );
};

export default NavbarGroup;
