import { Result } from 'antd';
import React from 'react';

const FiveHundred: React.FC = () => {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   fetch(API_URL).then((response) => navigate(-1));
  // }, []);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Result status="500" title="Work in progress" subTitle="Service under maintenance, please try again in a while" />
    </div>
  );
};

export default FiveHundred;
