import { SyncOutlined } from '@ant-design/icons';
import { Col, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import api from '../../../api/api';
import { errorNotification, successNotification } from '../../../components/UI/Toast/Toast';
import { Button } from '../../../components/UI/Button/Button';

interface Props {
  modified_at: any;
  sync_status: boolean;
  policyNumberPresent: boolean;
  payload: any;
  onResponse: ()=>void;
  icon: boolean;
}

const SyncClaims: React.FC<Props> = ({ sync_status, modified_at, payload, onResponse, icon, policyNumberPresent }) => {
  const [spin, setSpin] = useState<boolean>(false),
  syncClaim = () => {
    if (!spin && policyNumberPresent) {
      setSpin(true)
      api.claims_management.syncClaims(payload)
        .then((res: any) => {
          successNotification("Claim synced successfully")
          onResponse()
          setSpin(false)
        })
        .catch((err: any) => {
          errorNotification("There was some error. Please try again later")
          setSpin(false)
        })
    }
  };

  return <Row justify="end" align="middle" gutter={16}>
    {modified_at && <Col>
      <div className="last-synced">
        Last Synced: {moment(modified_at)?.format('DD MMM YYYY; h:mma')}
      </div>
    </Col>}
    {sync_status && <Col>
      <div className={`icon-boxed ${policyNumberPresent ? 'cta' : 'cta-disabled'}`} onClick={syncClaim}>
        {icon && <SyncOutlined disabled={!policyNumberPresent} spin={spin} style={{ fontSize: "22px" }} />}
        {!icon && <Button type='secondary' size='small' disabled={!policyNumberPresent}><SyncOutlined spin={spin} /> Sync Now</Button>}
      </div>
    </Col>}
  </Row>
}

export default SyncClaims;
