import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import masterdataReducer from './masterdata';
import memberDataReducer from './memberdata';
import brokerConfigReducer from './brokerconfig';

const store = configureStore({
  reducer: {
    auth: authReducer,
    memberData: memberDataReducer,
    masterdata: masterdataReducer,
    brokerconfig: brokerConfigReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
