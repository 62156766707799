import React from 'react';
import { toTitleCase } from '../../../utils/helper';
import './_tag.scss';

interface Props {
  children: string;
  type: string;
  size?: string;
}

const SUCCESS = ['success', 'accepted', 'active', 'settled', 'valid', 'sent', 'settled'];

const INTERMEDIATE = ['sent_to_insurer'];

const FAIL = [
  'fail',
  'rejected',
  'cancelled',
  'expired',
  'inactive',
  'warning',
  'expired',
  'unsent',
  'rejected',
  'withdrawn',
];

const PENDING = [
  'pending',
  'in process',
  'processing',
  'waiting',
  'unpaid',
  'unconfirmed',
  'unverified',
  'unapproved',
  'progress',
  'in-progress',
  'in progress',
  'in process',
  'in-process',
  'addition in process',
  'removal in process',
  'edition in process',
  'document pending',
  'document received',
  'document submitted',
  'in process',
  'query unresolved',
  'query resolved',
  'approved',
];

const INVITED = ['invited', 'inviting', 'invites'];
const ADDITION_IN_PROCESS = ['addition', 'addition_in_process'];
const DELETION_IN_PROCESS = ['deletion', 'deletion_in_process'];

const Tag: React.FC<Props> = ({ children, type, size }) => {
  let className = '';
  if (SUCCESS?.includes(type?.toLowerCase())) {
    className = 'success';
  } else if (FAIL?.includes(type?.toLowerCase())) {
    className = 'fail';
  } else if (INTERMEDIATE?.includes(type?.toLowerCase())) {
    className = 'intermediate';
  } else if (PENDING?.includes(type?.toLowerCase())) {
    className = 'pending';
  } else if (INVITED?.includes(type?.toLowerCase())) {
    className = 'invited';
  } else if (ADDITION_IN_PROCESS?.includes(type?.toLowerCase())) {
    className = 'addition';
  } else if (DELETION_IN_PROCESS?.includes(type?.toLowerCase())) {
    className = 'deletion';
  } else {
    className = 'pending';
  }


  return <div className={`tag ${className} ${size === 'large' ? 'large' : ''}`}>{toTitleCase(children)}</div>;
};

export default Tag;
