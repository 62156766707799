import { useEffect, useState } from "react";
import Loader from "../../../../../components/Loader/Loader";
import ProductPlanSelection from "../../../../../components/UI/ProductPlanSelection/ProductPlanSelection"
import TableCTAComponent from "../../../../../components/UI/Table/TableCTAComponent";
import { errorHandler, extractObjects } from "../../../../../utils/helper";
import { Col, Form, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Button } from "../../../../../components/UI/Button/Button";
import { useNavigate, useOutletContext } from "react-router-dom";
import { OffersAndPlansContextType } from "./OffersAndPlans";


const UpdateOffersAndPlans: React.FC = () => {
  const [plans, setPlans] = useState<Array<any> | undefined>(undefined),
    context = useOutletContext<OffersAndPlansContextType>(),
    [offers, setOffers] = useState<Array<any> | undefined>(undefined),
    [loading, setLoading] = useState<boolean>(true),
    [checklistCount, setChecklistCount] = useState<number | undefined>(undefined),
    navigate = useNavigate(),
    [planForm] = useForm(),
    [offerForm] = useForm(),
    appendUnopenedAccordians = (extractedData: Array<any>, masterdata: Array<any>) => {
      return masterdata?.map((item: any) => {
        let x = extractedData?.find((y: any) => item.uuid === y.uuid)
        if (x) {
          return x
        } else return item
      })
    },
    reformatPayload = (plans: any, offers: any) => {
      return {
        plans: appendUnopenedAccordians(extractObjects(plans), context?.data?.plans),
        offers: appendUnopenedAccordians(extractObjects(offers), context?.data?.offers),
      }
    },
    reformatPlans = (plans: any) => {
      let output = [] as any
      for (let i = 0; i < plans?.length; i++) {
        let obj = output?.find((item: any) => item?.product_name === plans[i]?.product_name && item?.provider_name === plans[i]?.provider_name)
        if (!obj) {
          output = [...output, {
            product_name: plans[i]?.product_name,
            provider_name: plans[i]?.provider_name,
            plans: [{
              uuid: plans[i]?.uuid,
              plan_name: plans[i]?.plan_name,
              cost_price: plans[i]?.cost_price,
              selling_price: plans[i]?.selling_price,
              offer_selling_price: plans[i]?.offer_selling_price,
              is_active: plans[i]?.is_active,
            }]
          }]
        } else {
          output = output?.map((item: any) => {
            if (item?.product_name === plans[i]?.product_name && item?.provider_name === plans[i]?.provider_name) {
              return {
                ...item,
                plans: [...item.plans, {
                  uuid: plans[i]?.uuid,
                  plan_name: plans[i]?.plan_name,
                  cost_price: plans[i]?.cost_price,
                  selling_price: plans[i]?.selling_price,
                  offer_selling_price: plans[i]?.offer_selling_price,
                  is_active: plans[i]?.is_active,
                }]
              }
            } else return item
          })
        }
      }
      setPlans(output)
    },
    reformatOffers = (offers: any) => {
      let output = [] as any
      for (let i = 0; i < offers?.length; i++) {
        let obj = output?.find((item: any) => item?.provider_name === offers[i]?.provider_name)
        if (!obj) {
          output = [...output, {
            provider_name: offers[i]?.provider_name,
            offers: [{
              uuid: offers[i]?.uuid,
              offer_name: offers[i]?.offer_name,
              is_active: offers[i]?.is_active,
            }]
          }]
        } else {
          output = output?.map((item: any) => {
            if (item?.provider_name === offers[i]?.provider_name) {
              return {
                ...item,
                offers: [...item.offers, {
                  uuid: offers[i]?.uuid,
                  offer_name: offers[i]?.offer_name,
                  is_active: offers[i]?.is_active,
                }]
              }
            } else return item
          })
        }
      }
      setOffers(output)
    },
    updateChecklistCount = () => {
      setChecklistCount(extractObjects(reformatPayload(planForm.getFieldsValue(), offerForm.getFieldsValue()))?.filter((item: any) => item.is_active)?.length)
    },
    submit = () => {
      planForm.validateFields().then((values) => {
        offerForm.validateFields().then((values) => {
          context?.submit(reformatPayload(planForm.getFieldsValue(), offerForm.getFieldsValue()))
        }).catch((error) => {
          errorHandler('Please enter all required fields')
        })
      }).catch((error) => {
        errorHandler('Please enter all required fields')
      })
    };

  useEffect(() => {
    if (context?.data) {
      reformatPlans(context?.data?.plans)
      reformatOffers(context?.data?.offers)
    }
  }, [context?.data])

  useEffect(() => {
    if (plans && offers) {
      setLoading(false)
      updateChecklistCount()
    }
  }, [plans, offers])

  return <Loader
    loading={loading}
  >
    <TableCTAComponent
    // search={{
    //   placeholder: "Search plans or offers",
    //   key: "search",
    //   size: "large"
    // }}
    summary={{
      totalCount: context?.data?.plans?.concat(context?.data?.offers)?.length,
      checklistCount: checklistCount
    }} />
    <div className="form-bottom-border">
      <Form
        form={planForm}
        onChange={updateChecklistCount}
      >
        {plans?.map((item: any, index: number) => {
          return (
            <ProductPlanSelection
            type="plan"
            fieldsToShow={context?.fieldsToShow?.plans}
            fieldsToDisable={context?.fieldsToDisable?.plans}
            key={index}
            data={item}
            form={planForm}
            index={index}
            />
          )
        })}
      </Form>
      <Form
        form={offerForm}
        onChange={updateChecklistCount}
      >
        {offers?.map((item: any, index: number) => {
          return (
            <ProductPlanSelection
            type="offer"
            fieldsToDisable={context?.fieldsToDisable?.offers}
            fieldsToShow={context?.fieldsToShow?.offers}
            key={index}
            data={item}
            form={offerForm}
            index={index}
            />
          )
        })}
      </Form>
    </div>
    <Row gutter={16} justify="end" className="mt-24">
      <Col>
        <Button type="secondary" onClick={() => navigate(context?.backNavigationLink)}>Back</Button>
      </Col>
      <Col>
        <Button type="primary" onClick={submit}>Save</Button>
      </Col>
    </Row>
  </Loader>
}

export default UpdateOffersAndPlans;
