import { DoubleRightOutlined, StarFilled, StarOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, WarningOutlined, SyncOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import api from '../../../api/api';
import EntityListing from '../../../components/EntityListing/EntityListing';
import Loader from '../../../components/Loader/Loader';
import Tag from '../../../components/UI/Tags/Tag';
import { successNotification } from '../../../components/UI/Toast/Toast';
import { DEFAULT_PAGE_SIZE } from '../../../constants/config';
import { EDIT_PERMISSION } from '../../../constants/types';
import { errorHandler, toTitleCase } from '../../../utils/helper';
import { Col, Popover, Row, Tooltip } from 'antd';
import Breadcrumbs from '../../../components/header/Breadcrumbs';
import moment from 'moment';
import SyncClaims from './SyncClaims';


export const renderStatus = (value: string) => {
  if (value) {
    return <Tag type={value?.toLowerCase()}>{value}</Tag>;
  } else {
    return null
  }
};

const renderClaimID = (record: any) => {
  if (!record?.action_pending?.is_treatment_details_filled || !record?.action_pending?.are_all_documents_uploaded || !record?.action_pending?.are_all_communication_sent) {
    let content = '';
    let pending_actions = [];

    if (!record?.action_pending?.is_treatment_details_filled) {
      pending_actions.push('Treatment details');
    }
    if (!record?.action_pending?.are_all_documents_uploaded) {
      pending_actions.push('Documents');
    }
    if (!record?.action_pending?.are_all_communication_sent) {
      pending_actions.push('Communication');
    }

    if (pending_actions.length === 3) {
      content = "Treatment details, Documents and Communication pending"
    } else {
      content = pending_actions.join(' and ') + ' pending';
    }

    return (
      <span>
        &nbsp;&nbsp;
        <Popover content={content}>
          <ExclamationCircleFilled className="incomplete-alert" />
        </Popover>
        {record.claim_id}
      </span>
    );
  } else {
    return <span>&nbsp;&nbsp;{record?.claim_id}</span>;
  }
};

const ClaimsListing: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams(),
    listOfProducts = useSelector((state: any) => state.masterdata.listOfProducts)?.map((item: any) => {
      return { label: item.name, value: item.id }
    }),
    listOfProviders = useSelector((state: any) => state.masterdata.listOfProviders)?.map((item: any) => {
      return { label: item.brand_name, value: item.id }
    }),
    controls = useSelector((state: any) => state.auth.controls),
    navigate = useNavigate(),
    [checkList, setCheckList] = useState([] as any),
    source = axios.CancelToken.source(),
    [pendingClaims, setPendingClaims] = useState([] as any),
    [count, setCount] = useState<number | null>(null),
    [btnLoading, setBtnLoading] = useState(false),
    [loading, setLoading] = useState(false),
    [organisationName, setOrganisationName] = useState(''),
    [claimsManagers, setClaimsManagers] = useState<null | Array<any>>(null),
    location = useLocation() as any,
    editPermission = useSelector((state: any) => state.auth.permissions)?.claims?.includes(EDIT_PERMISSION),
    [activeTab, setActiveTab] = useState(location?.state?.type || searchParams.get('tab') || 'CORPORATE'),
    [claimsConfig, setClaimsConfig] = useState<any>(),
    // choices = useSelector((state: any) => state.masterdata.choices),
    [choices, setChoices] = useState({} as any),
    { id } = useParams(),
    [params, setParams] = useState({
      limit: parseInt(searchParams.get('limit') ?? DEFAULT_PAGE_SIZE),
      offset: parseInt(searchParams.get('offset') ?? '0'),
      ...(searchParams.get('product_id') && { product_id: searchParams.getAll('product_id').map((item)=>parseInt(item)) }),
      ...(searchParams.get('insurer') && { insurer: searchParams.getAll('insurer').map((item)=>parseInt(item)) }),
      ...(searchParams.get('policy_id') && { policy_id: searchParams.getAll('policy_id').map((item)=>parseInt(item)) }),
      ...(searchParams.get('status') && { status: searchParams.getAll('status') }),
      ...(searchParams.get('claim_type') && { claim_type: searchParams.get('claim_type') }),
      ...(searchParams.get('tpa_id') && { tpa_id: searchParams.getAll('tpa_id').map((item)=>parseInt(item)) }),
      ...(searchParams.get('claims_manager_id') && { claims_manager_id: parseInt(searchParams.get('claims_manager_id') ?? '0') }),
      ...(searchParams.get('marked_important') && { marked_important: searchParams.get('marked_important') }),
    } as any),
    fetchChoices = () => {
      api.choices.getChoices({ organisation_id: id })
        .then((res) => {
          setOrganisationName(res?.data?.organisation_name);
          setChoices(res?.data);
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
        })
    },
    fetchClaimsManagers = () => {
      api.clientaddition
        .userList('claim_manager')
        .then((response) => {
          setClaimsManagers(response.data);
        })
        .catch((error) => { });
    },
    markClaim = (record: any, status: boolean) => {
      api.claims_management
        .updateClaim(record?.uuid, { marked_important: status })
        .then((res) => {
          successNotification(res?.message);
          setParams((prev: any) => {
            return { ...prev };
          });
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
        });
    },
    ClaimsTableHeader = [
      {
        title: '',
        dataIndex: 'marked_important',
        key: 'marked_important',
        render: (value: any, record: any) => {
          return (
            <span>
              {value ? (
                <StarFilled onClick={() => markClaim(record, false)} className="important" />
              ) : (
                <StarOutlined onClick={() => markClaim(record, true)} className="" />
              )}
            </span>
          );
        },
      },
      {
        title: '',
        dataIndex: 'sync_status',
        key: 'sync_status',
        render: (value: any, record: any) => {
          if (value) {
            if (record?.additional_details?.auto_generated) {
              if (!record?.additional_details?.member_found) {
                return (
                  <Tooltip title="The claim is imported from insurer/TPA's database. We couldn't identify the member details">
                    <WarningOutlined style={{ color: "red" }} />
                  </Tooltip>
                )
              } else {
                return (
                  <Tooltip title="This claim was not found in your database, it is imported from insurer/TPA's databse">
                    <ExclamationCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                )
              }
            }
          }
        },
      },
      {
        title: 'Claim ID',
        dataIndex: 'claim_id',
        key: 'claim_id',
        render: (value: any, record: any) => {
          return renderClaimID(record) || '-';
        }
      },
      ...(!id
        ? [
          {
            title: 'Client Name',
            dataIndex: ['organisation', 'organisation_name'],
            key: ['organisation', 'organisation_name'],
            render: (value: string) => {
              return value || '-';
            },
          },
        ]
        : []),
      {
        title: 'Employee Name',
        dataIndex: 'full_name',
        key: 'full_name',
        render: (value: string, record: any) => {
          return toTitleCase(value) || toTitleCase(record?.additional_details?.response?.member_name) || '-';
        },
      },
      {
        title: 'Employee ID',
        dataIndex: 'employee_id',
        key: 'employee_id',
        render: (value: string, record: any) => {
          return value || toTitleCase(record?.additional_details?.response?.employee_id) || '-';
        },
      },
      {
        title: 'Raised for',
        dataIndex: ['raised_for', 'name'],
        key: 'raised_for_name',
        render: (value: any, record: any) => {
          return toTitleCase(value) || toTitleCase(record?.additional_details?.response?.raised_for) || '-';
        },
      },
      {
        title: 'Relation',
        dataIndex: ['raised_for', 'relation'],
        key: 'raised_for_relation',
        render: (value: any, record: any) => {
          return toTitleCase(value) || toTitleCase(record?.additional_details?.response?.relation) || '-';
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: string) => {
          return renderStatus(toTitleCase(value));
        },
      },
      // {
      //   title: 'Health ID/Product ID',
      //   dataIndex: 'health_id',
      //   key: 'health_id',
      //   render: (value: string) => {
      //     return value || '-';
      //   },
      // },
      {
        title: 'Product',
        dataIndex: ['product', 'name'],
        key: 'product',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'Provider',
        dataIndex: 'provider_name',
        key: 'provider_name',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'TPA',
        dataIndex: ['tpa', 'name'],
        key: 'tpa',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'Processed By',
        dataIndex: 'processed_by',
        key: 'processed_by',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'Claims Manager',
        dataIndex: ['claims_manager', 'name'],
        key: ['claims_manager', 'name'],
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'Claim Type',
        dataIndex: 'type',
        key: 'type',
        render: (value: string) => {
          return toTitleCase(value) || '-';
        },
      },
      {
        title: 'Claim Amount',
        dataIndex: 'claimed_amount',
        key: 'claimed_amount',
        render: (value: string) => {
          return value ? '₹ ' + value : '-';
        },
      },
      {
        title: 'Settled Amount',
        dataIndex: 'settled_amount',
        key: 'settled_amount',
        render: (value: string) => {
          return value ? '₹ ' + value : '-';
        },
      },
      {
        ...(editPermission && {
          title: '',
          dataIndex: 'edit',
          fixed: 'right',
          render: (text: any, record: any) =>
            renderCTA(record)
        })
      },
    ],
    IndividualClaimsTableHeader = [
      {
        title: 'Claim ID',
        dataIndex: 'claim_id',
        key: 'claim_id',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'Raised for',
        dataIndex: ['raised_for'],
        key: 'raised_for',
        render: (value: any) => {
          return value ? toTitleCase(`${value?.name} (${value?.relation})`) : 'Self';
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: string) => {
          return renderStatus(toTitleCase(value));
        },
      },
      {
        title: 'Insurer',
        dataIndex: 'provider_name',
        key: 'provider_name',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'TPA',
        dataIndex: ['tpa', 'name'],
        key: 'tpa',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'Claim Amount',
        dataIndex: 'claimed_amount',
        key: 'claimed_amount',
        render: (value: string) => {
          return value ? '₹ ' + value : '-';
        },
      },
      {
        title: 'Settled Amount',
        dataIndex: 'settled_amount',
        key: 'settled_amount',
        render: (value: string) => {
          return value ? '₹ ' + value : '-';
        },
      },
      {
        ...(editPermission && {
          title: '',
          dataIndex: 'edit',
          fixed: 'right',
          render: (text: any, record: any) =>
            renderCTA(record)
        })
      },
    ],
    renderCTA = (record: any) => {
      return (
        <span className='cta' onClick={(e) => navigate(`view-claim/${record?.uuid}`)}>
          <DoubleRightOutlined />
        </span>
      );
    },
    getPendingClaimsStatus = () => {
      api.claims_management
        .pendingClaims()
        .then((res) => {
          setPendingClaims(res);
        })
        .catch((error) => {
          errorHandler(error?.response?.data);
        });
    },
    getCheckList = (item: any) => {
      setCheckList(item);
    },
    routes = [
      {
        path: '/client-manager/corporate/:id',
        props: { value: organisationName },
      },
    ],
    tabChangeHandler = (tab: string) => {
      setActiveTab(tab);
      setSearchParams({ tab: tab }, { replace: true });
      setParams((prev: any) => ({ ...prev, offset: 0, limit: parseInt(DEFAULT_PAGE_SIZE) }));
    },
    tabs = {
      activeTab: activeTab,
      tabChangeHandler: tabChangeHandler,
      list: [
        {
          key: 'CORPORATE',
          text: 'Corporate',
          notification: pendingClaims?.is_corporate_pending_claims,
        },
        ...(controls?.is_individual_section ? [{
          key: 'INDIVIDUAL',
          text: 'Individual',
          notification: pendingClaims?.is_individual_pending_claims,
        }] : []),
      ],
    },
    downloadMIS = () => {
      let payload = { ...params, category: activeTab, organisation_uuid: id };
      delete payload.limit;
      delete payload.offset;
      if (checkList.length === 0) {
        api.claims_management
          .downloadMIS(payload)
          .then((res) => {
            successNotification(res?.message);
          })
          .catch((err) => {
            errorHandler(err?.response?.data);
          });
      } else {
        api.claims_management
          .downloadSelectedMIS({ claim_uuids: checkList })
          .then((res) => {
            successNotification(res?.message);
          })
          .catch((err) => {
            errorHandler(err?.response?.data);
          });
      }
    },
    getClaimsConfig = () => {
      api.claims_management.claimConfig({
        organisation_uuid: id
      }).then((res: any) => {
        setClaimsConfig(res?.data)
      }).catch((err: any) => {
        errorHandler(err?.res?.data)
      })
    },
    cta = {
      text: [
        {
          text: 'Download MIS',
          icon: 'download',
          onClick: () => {
            downloadMIS();
          }
        },
        ...(id ? [{
          text: "View Analytics",
          icon: 'analytics',
          disabled: !count,
          onClick: () => {
            navigate('analytics')
          }
        }] : [])
      ],
      button: [
        {
          text: `Add claim`,
          editPermission: editPermission,
          onClick: () => {
            if (id) {
              if (activeTab === 'INDIVIDUAL') {
                navigate(`/client-manager/individual/claims-manager/${id}/add-claim`, {
                  state: { type: 'INDIVIDUAL' },
                });
              } else if (activeTab === 'CORPORATE') {
                navigate(`/claims-manager/${id}/add-claim`, { state: { type: 'CORPORATE' } });
              }
            } else {
              navigate(`/claims-manager/add-claim`, { state: { type: activeTab } });
            }
          }
        }
      ],
    };
  let filterList = id ? ['claim_status', 'claim_managers', 'policy_id', 'product_id', 'insurer', 'claim_type', 'tpa', 'marked_important'] : ['claim_status', 'claim_managers', 'product_id', 'insurer', 'claim_type', 'tpa', 'marked_important'];

  useEffect(() => {
    setLoading(true);
    getPendingClaimsStatus();
    fetchClaimsManagers();
  }, []);

  useEffect(() => {
    if (claimsManagers && choices) {
      setLoading(false);
    }
  }, [claimsManagers, choices]);

  useLayoutEffect(() => {
    if (id) {
      getClaimsConfig()
    }
    fetchChoices();
  }, [])

  return (
    <Loader
      screen='claims'
    >
      <Row justify='space-between' align='bottom'>
        <Col>
          <Breadcrumbs routes={routes} />
        </Col>
        {claimsConfig?.api_sync && <Col>
          <SyncClaims icon={false} modified_at={claimsConfig?.modified_at} sync_status={claimsConfig?.api_sync} policyNumberPresent={claimsConfig?.policy_number_added} onResponse={() => { }} payload={{
            organisation_uuid: id
          }} />
        </Col>}
      </Row>
      <div className="claims-manager">
        <EntityListing
          hideBreadcrumb={true}
          setParentCount={setCount}
          routes={routes}
          checkList={checkList}
          getCheckList={getCheckList}
          params={params}
          btnLoading={btnLoading}
          header={activeTab === 'CORPORATE' ? ClaimsTableHeader : IndividualClaimsTableHeader}
          setParams={setParams}
          urlOffset={params.offset}
          urlLimit={params.limit}
          tableDataApi={() =>
            api.claims_management
              .getClaimsListV1({
                ...params,
                category: activeTab,
                ...(activeTab === 'CORPORATE' && { organisation_id: id }),
                ...(activeTab === 'INDIVIDUAL' && { user_id: id }),
              }, source?.token)
          }
          searchKey="full_name"
          type="claims"
          searchPlaceholder="Search"
          filter={{
            list: filterList,
            notification:
              params?.product_id?.length > 0 ||
              params?.insurer?.length > 0 ||
              params?.policy_id?.length > 0 ||
              params?.status?.length > 0 ||
              params?.claim_type?.length > 0 ||
              params?.tpa_id?.length > 0 ||
              params?.marked_important ||
              params?.claims_manager_id > 0,
            products: listOfProducts,
            providers: listOfProviders,
            policies: choices?.all_organisation_policy,
            claimStatus: choices?.claim_status_choices,
            claimTypes: choices?.claim_type_choices,
            claimManagers: claimsManagers,
            tpas: choices?.tpa_list,
            loading: loading,
            btnLoading: btnLoading,
            visible: true,
          }}
          sort={{
            notification: params?.ordering?.length > 0,
            options: [
              {
                key: 'claimed_amount_sorter',
                title: 'Claim Amount',
                sort: [
                  {
                    type: 'asc',
                    key: '-claimed_amount',
                  },
                  {
                    type: 'desc',
                    key: 'claimed_amount',
                  },
                ],
              },
              {
                key: 'raised_on_sorter',
                title: 'Date of Creation',
                sort: [
                  {
                    key: '-raised_on',
                    type: 'asc',
                  },
                  {
                    key: 'raised_on',
                    type: 'desc',
                  },
                ],
              },
            ],
          }}
          tabs={!id && tabs}
          cta={cta}
        />
      </div>
    </Loader>
  );
};

export default ClaimsListing;
