import { Alert } from 'antd';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import './_header.scss';

interface Props {
  routes?: any;
  extraProps?: any;
  onlyActive?: boolean;
}
const Breadcrumbs: React.FC<Props> = ({ routes, extraProps, onlyActive = false }) => {
  const breadcrumbs = useBreadcrumbs(routes),
    searchParams = new URLSearchParams(window.location.search);
  return (
    <div className="header">
      <span className={`breadcrumb`}>
        {breadcrumbs?.map(({ match, breadcrumb }: any, index) => {
          if (index < breadcrumbs.length - 1 && !match?.route?.props?.killBreadcrumb) {
            if (!onlyActive) {
              return (
                <span key={index}>
                  <NavLink
                    key={match.pathname}
                    to={match?.route?.props?.overRideRedirect ? match?.route?.props?.overRideRedirect : match.pathname}
                    className="clean-anchor"
                  >
                    {match?.route?.props?.value ? match?.route?.props?.value : breadcrumb}
                  </NavLink>
                  {index < breadcrumbs.length - 2 && <span>&nbsp;/&nbsp;</span>}
                </span>
              )
            };
          } else if (!match?.route?.props?.killBreadcrumb) {
            return (
              <div className={`active-element`} key={index}>
                <NavLink to={match.pathname} className="clean-anchor">
                  {breadcrumb}
                </NavLink>
                {/* &nbsp; */}
                {extraProps &&
                  extraProps?.map((item: any, index: number) => {
                    return item;
                  })}
              </div>
            );
          }
        })}
      </span>
      {searchParams.get('info') && <div className='mt-32'>
        <Alert
          message={searchParams.get('info')}
          type="warning"
        // closable
        />
      </div>}
    </div>
  );
};

export const ActiveBreadcrump = (props: any) => {
  return <span>{props?.value}</span>;
};

export default Breadcrumbs;
