import './_dashboardAnalytics.scss';

interface DashboardAnalyticsProps {
  title: string;
  primaryValue: number | string;
  secondaryValue?: number | string;
}

const DashboardAnalytics: React.FC<DashboardAnalyticsProps> = ({title, primaryValue, secondaryValue}) => {
  return <div className="analytics-box">
    <div className="analytics-title">
      {title}
    </div>
    <div className="primary-value">
      {primaryValue}
    </div>
    <div className="secondary-value">
      {secondaryValue || ""}
    </div>
  </div>
};

export default DashboardAnalytics;
