import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import api from "../../../../api/api";
import { errorHandler } from "../../../../utils/helper";
import { successNotification } from "../../../../components/UI/Toast/Toast";
import Loader from "../../../../components/Loader/Loader";
import Breadcrumbs from "../../../../components/header/Breadcrumbs";

const AgentOffersAndPlans: React.FC = () => {
  const [details, setDetails] = useState<undefined | any>(undefined),
    [loading, setLoading] = useState<boolean>(true),
    [data, setData] = useState<undefined | any>(undefined),
    { uuid } = useParams(),
    getOffersAndPlans = () => {
      api.offers.getBrokerOfferPlan({
        broker_id: uuid
      })
        .then((res) => {
          setData(res?.data)
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
        })
    },
    getDetails = () => {
      api.partner_management
        .getPartnerDetails(uuid)
        .then((response) => {
          setDetails({
            brand_name: response.data?.brand_name,
          });
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
        });
    },
    submit = (payload: any) => {
      api.offers.setBrokerOfferPlan({ ...payload, broker_id: uuid })
        .then((res) => {
          getOffersAndPlans()
          successNotification("Plans and offers updated")
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
    },
    columns = [
      {
        title: "Offer and Plan details",
        key: 'plan_name',
        dataIndex: 'plan_name',
        render: (value: any, record: any) => {
          return record?.plan_name || record?.offer_name
        }
      },
      {
        title: "Provider",
        key: 'provider_name',
        dataIndex: 'provider_name',
      },
      {
        title: "Cost Price",
        key: 'cost_price',
        dataIndex: 'cost_price',
      },
    ],
    routes = [
      {
        path: '/agent-manager/:uuid',
        props: { value: details?.brand_name },
      },
    ];

  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    getDetails()
  }, [])

  useEffect(() => {
    if (details) {
      getOffersAndPlans()
    }
  }, [details])

  return <Loader loading={loading}>
    <Breadcrumbs routes={routes} />
    <Outlet context={{
      details: details,
      data: data,
      submit: submit,
      fieldsToShow: {
        plans: ['Cost Price'],
        offers: []
      },
      fieldsToDisable: {
        plans: [],
        offers: []
      },
      backNavigationLink: `/agent-manager/${uuid}/offers-and-plans`,
      columns: columns
    }} />
  </Loader>
}

export default AgentOffersAndPlans;
