import { Col, Drawer, Form, Image, Input, InputNumber, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Dragger from 'antd/lib/upload/Dragger';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtmlPuri from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import api from '../../../../api/api';
import apiEndpoints from '../../../../api/apiEndPoints';
import Loader from '../../../../components/Loader/Loader';
import { Button as ButtonUI } from '../../../../components/UI/Button/Button';
import { errorNotification, successNotification } from '../../../../components/UI/Toast/Toast';
import { EDIT_PERMISSION } from '../../../../constants/types';
import { errorHandler, getAccessToken, onInputChangeHandler } from '../../../../utils/helper';
import './_products.scss';

interface Props {
  onClose: any;
  visible: any;
  record?: any;
}

const StepsToAvail: React.FC<Props> = ({ onClose, visible, record }) => {
  const [loading, setLoading] = useState<boolean>(true),
    [btnLoading, setBtnLoading] = useState<boolean>(false),
    [form] = Form.useForm(),
    { productid } = useParams<string>(),
    [steps, setSteps] = useState<any>(),
    [editorState, setEditorState] = useState<EditorState>(),
    [image, setImage] = useState<string>(),
    [errorMessage, setErrorMessage] = useState<any>(),
    [providers, setProviders] = useState<Array<any> | undefined>(undefined),
    props = {
      name: 'image',
      accept: '.png',
      multiple: false,
      action: apiEndpoints.imageUpload,
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
      },
      maxCount: 1,
      onerror(e: any) {
        errorNotification('Error : Please recheck the image and try again');
      },
    },
    editPermission = useSelector((state: any) => state.auth.permissions)?.products?.includes(EDIT_PERMISSION),
    normFile = (e: any) => {
      if (Array.isArray(e)) {
        // console.log(e);
        return e;
      }
      setImage(e?.fileList?.[0]?.response?.url);
      return e?.fileList?.[0]?.response?.url;
    },
    onSaveHandler = () => {
      if (editorState) {
        form.validateFields()
          .then((values) => {
            setBtnLoading(true);
            api.product
              .addProductPlan({
                product: values?.product,
                plan_code: values?.plan_code,
                plan_name: values?.plan_name,
                benefits_html: draftToHtmlPuri(convertToRaw(editorState.getCurrentContent())),
                image: values?.image || image,
                description: values?.description,
                cost_price: values?.cost_price,
                selling_price: values?.selling_price,
              })
              .then((res) => {
                setBtnLoading(false);
                successNotification('Plan successfully added');
                onClose(true);
              })
              .catch((err) => {
                setBtnLoading(false);
                setErrorMessage(err?.response?.data?.error);
                errorHandler(err?.response?.data);
              });
          })
      }
      else {
        errorNotification("Please enter the steps to avail");
      }
    },
    onUpdateHandler = () => {
      if (editorState && record) {
        form.validateFields()
          .then((values) => {
            setBtnLoading(true);
            api.product
              .updateProductPlan(record?.uuid, {
                product: values?.product || record?.product,
                plan_code: values?.plan_code,
                plan_name: values?.plan_name,
                benefits_html: draftToHtmlPuri(convertToRaw(editorState.getCurrentContent())),
                image: values?.image || image,
                description: values?.description,
                cost_price: values?.cost_price,
                selling_price: values?.selling_price,
              })
              .then((res) => {
                setBtnLoading(false);
                onClose(true);
                successNotification('Plan successfully updated');
              })
              .catch((err) => {
                setBtnLoading(false);
                setErrorMessage(err?.response?.data?.error);
                errorHandler(err?.response?.data);
              });
          })
      }
    },
    getProviders = () => {
      if (productid) {
        api.product.getProductList(productid)
          .then((res) => {
            setProviders(res?.data?.provider?.map((item: any) => ({ label: item?.brand_name, value: item?.id })));
          })
          .catch((err) => {
            errorHandler(err?.response?.data);
          });
      }
    },
    onErrorChangeHandler = (key: Array<string>) => {
      onInputChangeHandler(key, setErrorMessage, errorMessage);
    };


  useEffect(() => {
    if (steps) {
      const blocksFromHtml = htmlToDraft(steps);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [steps]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        product: record?.product,
        plan_code: record?.plan_code,
        plan_name: record?.plan_name,
        description: record?.description,
        cost_price: record?.cost_price,
        selling_price: record?.selling_price,
      })
      setSteps(record?.benefits_html);
      setImage(record?.image);
    }
  }, [record]);

  useEffect(() => {
    getProviders();
    //   // setTimeout(() => {
    //   //   setLoading(false);
    //   // }, 2000);
  }, []);

  useEffect(() => {
    if (providers !== undefined) {
      setLoading(false)
    }
  }, [providers])


  return (
    <Drawer title={'How to avail'} onClose={() => onClose(false)} destroyOnClose visible={visible} size={'large'} closable={false}>
      <Loader
        loading={loading}
      >
        <Form form={form}>
          <Form.Item
            name="product"
            label="Select Provider"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Select onChange={() => { onErrorChangeHandler(["product"]) }} options={providers} />
          </Form.Item>
          {errorMessage?.product && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {errorMessage?.product[0]}
            </div>
          )}

          <Form.Item
            name="plan_code"
            label="Plan Code"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input onChange={() => { onErrorChangeHandler(["plan_code"]) }} />
          </Form.Item>
          {errorMessage?.plan_code && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {errorMessage?.plan_code[0]}
            </div>
          )}
          <Form.Item
            name="plan_name"
            label="Plan Name"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input onChange={() => { onErrorChangeHandler(["plan_name"]) }} />
          </Form.Item>
          {errorMessage?.plan_name && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {errorMessage?.plan_name[0]}
            </div>
          )}
          <Form.Item
            name="description"
            label="Description"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <TextArea rows={2} />
          </Form.Item>
          {errorMessage?.description && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {errorMessage?.description[0]}
            </div>
          )}

          <Form.Item
            label="Cost Price"
            name="cost_price"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <InputNumber
              placeholder="Enter cost price"
              min={0}
              size="small"
              addonBefore="₹"
              formatter={(value) => (value ? parseFloat(value)?.toLocaleString('en-IN') : '')}
              className="w-addon"
            />
          </Form.Item>
          {errorMessage?.cost_price && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {errorMessage?.cost_price[0]}
            </div>
          )}

          <Form.Item
            label="Default Selling Price"
            name="selling_price"
            validateTrigger="onBlur"
            labelCol={{ span: 24 }}
          >
            <InputNumber
              placeholder="Enter default selling price"
              min={0}
              size="small"
              addonBefore="₹"
              formatter={(value) => (value ? parseFloat(value)?.toLocaleString('en-IN') : '')}
              className="w-addon"
            />
          </Form.Item>
          {errorMessage?.selling_price && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {errorMessage?.selling_price[0]}
            </div>
          )}


          <Form.Item
            name="benefits_html"
            label="Add the steps by which the user can avail the benefits for this product"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            required
          >
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbar={{
                options: ['inline', 'list', 'link', 'fontSize', 'fontFamily', 'textAlign'],
                inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] },
                list: { options: ['unordered', 'ordered'] },
                fontSize: { options: [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96], defaultSize: 12, className: 'default-font' },
                fontFamily: { options: ['Inter'], defaultOption: 'Inter', className: 'default-font' },
                textAlign: { options: ['left', 'center', 'right', 'justify'] },
              }}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onChange={() => { onErrorChangeHandler(["benefits_html"]) }}
            />
          </Form.Item>
          {errorMessage?.benefits_html && (
            <div role="alert" className="ant-form-item-explain-error error-backend">
              {errorMessage?.benefits_html[0]}
            </div>
          )}
          <Form.Item
            name={'image'}
            label="Upload product image"
            valuePropName="image"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            getValueFromEvent={normFile}
          >
            <Dragger listType="picture" {...props} style={{ width: '200px', height: '300px' }}>
              {image ? (
                <>
                  <Image width={100} preview={false} src={image} alt="avatar" style={{ cursor: 'pointer' }} />
                  <div className="upload-replace">Replace</div>
                </>
              ) : (
                // <Button icon={<UploadOutlined />}>Click to Upload</Button>
                <div className="upload-replace">Upload</div>
              )}
            </Dragger>
          </Form.Item>

        </Form>
        <Row justify="start" gutter={24}>
          <Col className='mt-24'>
            <ButtonUI onClick={() => onClose(false)} type="secondary">
              Cancel
            </ButtonUI>
          </Col>
          <Col className='mt-24'>
            {visible === 'add' && <ButtonUI onClick={() => { onSaveHandler() }} editPermission={editPermission} type="primary" loading={btnLoading}>
              Save
            </ButtonUI>}
            {visible === 'edit' && <ButtonUI onClick={() => { onUpdateHandler() }} editPermission={editPermission} type="primary" loading={btnLoading}>
              Update
            </ButtonUI>}
          </Col>
        </Row>
      </Loader>
    </Drawer>
  );
};

export default StepsToAvail;
