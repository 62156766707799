export const getSuggestion = (isModification: boolean, isDeletion: boolean) => {
  if (isModification) {
      return `If the insurer calculated updated premium doesn't match with the above updated premium, then please recheck the sum insured and date of birth with insurer-approved data and make necessary edits.`;
  }
  if (isDeletion) {
      return `If the insurer calculated refund doesn't match with the above refund, then please recheck the risk end date with the insurer - approved data and make any necessary edits`;
  }
  return `If the insurer calculated premium doesn't match with the above premiums, then please recheck the rater and risk inception date with the insurer - approved data and make any necessary edits`;
};

export const getInsurerInputLabel = (isModification: boolean, isDeletion: boolean) => {
  if (isModification) return 'Insurer Calculated Total Updated Premium';
  if (isDeletion) return 'Insurer Calculated Refund';
  return 'Insurer Calculated Amount';
};

export const getQuestion = (isModification: boolean, isDeletion: boolean) => {
  const action = isModification ? 'total updated premium' : isDeletion ? 'refund' : 'premium';
  const baseQuestion = `Does the above calculated Total ${action} match with the ${action} provided by the insurer for this batch?`;
  return baseQuestion;
};

export const getTitle = (isModification: boolean, isDeletion: boolean) => {
  if (isModification) return 'Total Updated Premium';
  if (isDeletion) return 'Total Refund';
  return 'Total Premium';
};