import { Column } from "@ant-design/charts";

interface Props{
  data: any;
  xField: string;
  yField: string;
}

const ColumnPlot: React.FC<Props> = ({data, xField, yField}) => {
  const config = {
    data,
    xField: xField,
    yField: yField,
    xAxis: {
      title: {
        text: xField,
      }
    },
    yAxis: {
      title: {
        text: yField,
      }
    }
  };
  return <Column {...config} />;
}

export default ColumnPlot;
