import { Col, Row } from 'antd';
import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/api';
import FixedHeader from '../../components/FixedHeader/FixedHeader';
import Loader from '../../components/Loader/Loader';
import Navbar from '../../components/Navbar/Navbar';
import Router from '../../router/Router';
import { brokerConfigActions } from '../../store/brokerconfig';
import { masterdataActions } from '../../store/masterdata';
import { errorHandler } from '../../utils/helper';

const DashboardLayout: React.FC = () => {
  const name = useSelector((state: any) => state.auth.name),
    permissions = useSelector((state: any) => state.auth.permissions),
    controls = useSelector((state: any) => state.auth.controls),
    dispatch = useDispatch(),
    fetchProducts = () => {
      if (controls?.is_admin) {
        api.clientaddition
          .productList()
          .then((response) => {
            dispatch(masterdataActions.setListOfProducts(response.data));
          })
          .catch((error) => {
            errorHandler(error?.response?.data);
          });
      }
      else {
        api.agent.showProductList()
          .then((response) => {
            dispatch(masterdataActions.setListOfProducts(response?.data));
          })
          .catch((error) => {
            errorHandler(error?.response?.data);
          });
      }
    },
    fetchProvider = () => {
      if (controls?.is_admin) {
        api.clientaddition
          .providerList()
          .then((response) => {
            dispatch(masterdataActions.setListOfProviders(response.data));
          })
          .catch((error) => {
            errorHandler(error?.response?.data);
          });
      }
      else {
        api.agent.showProviderList()
          .then((response) => {
            dispatch(masterdataActions.setListOfProviders(response?.data));
          })
          .catch((error) => {
            errorHandler(error?.response?.data);
          });
      }
    },
    fetchChoices = () => {
      if (controls?.is_admin) {
        api.partner_management.choices()
          .then((res) => {
            dispatch(brokerConfigActions.setChoices({ choices: res?.data }));
          })
          .catch((err) => {
            errorHandler(err?.response?.data);
          });
      }
      api.choices
        .getChoices()
        .then((res) => {
          dispatch(masterdataActions.setChoices({ choices: res?.data }));
        })
        .catch((err) => {
          // console.log(err);
        });
    };

  useLayoutEffect(() => {
    if (name && Object?.keys?.(controls)?.length) {
      fetchProducts();
      fetchProvider();
      fetchChoices();
    }
  }, [name, controls]);

  return (
    <Loader
      loading={permissions && Object?.keys(permissions)?.length === 0}
    >
      <div className="container">
        <FixedHeader />
        <Row className="no-margin" justify="space-between">
          <Col span={4}>
            <Navbar />
          </Col>
          <Col span={19} className='main-page'>

            <Router />
          </Col>
          <Col span={1}></Col>
        </Row>
      </div>
    </Loader>
  );
};

export default DashboardLayout;
