import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { Alert, Badge, Col, Form, Row, Select, Spin, Statistic } from "antd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api/api";
import Loader from "../../../components/Loader/Loader";
import { Button } from '../../../components/UI/Button/Button';
import Card from "../../../components/UI/Card/Card";
import ColumnLinePlot from "../../../components/UI/Graphs/ColumnLinePlot";
import ColumnPlot from "../../../components/UI/Graphs/ColumnPlot";
import PiePlot from "../../../components/UI/Graphs/PiePlot";
import { errorNotification } from "../../../components/UI/Toast/Toast";
import Breadcrumbs, { ActiveBreadcrump } from "../../../components/header/Breadcrumbs";
import { errorHandler, reformatAmountInK, toTitleCase } from "../../../utils/helper";
import './_claimsmanager.scss';
import DoubleMixPlot from '../../../components/UI/Graphs/DoubleMixPlot';
import TripleMixPlot from '../../../components/UI/Graphs/TripleMixPlot';
import DashboardAnalytics from '../../../components/UI/DashboardAnalytics/DashboardAnalytics';
import moment from 'moment';
import { Pie } from '@ant-design/plots';
import DonutPlot from '../../../components/UI/Graphs/DonutPlot';
import { useSelector } from 'react-redux';

interface Params {
  organisation_uuid?: string;
  gender?: string;
  age_band?: string;
  relation?: Array<string>;
  policy_uuid?: string;
}

const ClaimsAnalytics: React.FC = () => {
  const { id } = useParams(),
    [params, setParams] = useState<null | Params>({ organisation_uuid: id }),
    [loading, setLoading] = useState<boolean>(true),
    [gender, setGender] = useState<Array<any> | undefined>(undefined),
    [relation, setRelation] = useState<Array<any> | undefined>(undefined),
    [policies, setPolicies] = useState<Array<any> | undefined>(undefined),
    [btnLoading, setBtnLoading] = useState<boolean>(false),
    [data, setData] = useState<any>(null),
    [clientConfig, setClientConfig] = useState<any>(null),
    brokerConfig = useSelector((state: any) => state?.brokerconfig?.settingsConfig),
    navigate = useNavigate(),
    [form] = Form.useForm(),
    [clientName, setClientName] = useState<string | undefined>(undefined),
    pdfExportComponent = useRef(null as any),
    getChoices = () => {
      api.choices.getChoices({ organisation_id: id })
        .then((res: any) => {
          setPolicies(res?.data?.all_organisation_policy)
          setClientName(res?.data?.organisation_name)
          getClientConfig(res?.data?.organisation_uuid)
        })
        .catch((err: any) => {
          errorHandler(err?.response?.data)
        });
    },
    exportPDFWithMethod = () => {
      setBtnLoading(true);
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }

      setTimeout(() => {
        setBtnLoading(false);
      }, 1000);
    },
    updateParams = () => {
      setParams((prev: any) => ({ ...prev, ...form.getFieldsValue() }));
    },
    getAnalyticsData = () => {
      api.claims_management.claimsAnalytics(params)
        .then((res: any) => {
          setData(res?.data);
        })
        .catch((err: any) => {
          errorHandler(err?.response?.data)
        });
    },
    getClientConfig = (uuid: string) => {
      api.clientaddition.getClientSettingConfig({
        organisation_id: uuid
      })
        .then((response) => {
          setClientConfig(response?.data);
        })
        .catch((error) => {
          errorHandler(error?.response?.data);
        });
    },
    routes = [
      {
        path: '/client-manager',
        props: { overRideRedirect: '/client-manager/corporate' },
      },
      {
        path: '/client-manager/corporate/:id',
        breadcrumb: ActiveBreadcrump,
        props: { value: clientName },
      },
    ],
    arrayTransform2Dto1D = (data: any) => {
      let newData: any = [];
      data?.map((item: any) => {
        newData.push(...item)
      })
      return newData;
    };

  useEffect(() => {
    setLoading(true);
    if (params) {
      getAnalyticsData();
    }
  }, [params]);

  useEffect(() => {
    if (data) {
      if (!relation) {
        setRelation(data?.relationship_wise_data?.total_lives?.map((item: any) => {
          return {
            label: toTitleCase(item?.relation), value: item?.relation
          }
        }))
      }
      if (!gender) {
        setGender(data?.gender_wise_data?.total_lives?.map((item: any) => {
          return {
            label: toTitleCase(item?.gender), value: item?.gender
          }
        }))
      }
    }
  }, [data])

  useEffect(() => {
    if (data) {
      if (Object.keys(data).length === 0) {
        errorNotification("No claims data available for this organisation")
      }
      else {
        if (relation && gender && policies) {
          setLoading(false);
        }
      }
    }
  }, [data, gender, relation, policies]);

  useLayoutEffect(() => {
    getChoices();
  }, []);

  return <>
    <Row justify='space-between' align='bottom'>
      <Col>
        <Breadcrumbs routes={routes} />
      </Col>
      <Col>
        <Button type='primary' onClick={exportPDFWithMethod} loading={btnLoading || loading}>Download Report</Button>
      </Col>
    </Row>
    <Form form={form} className="mt-24 mb-48">
      <Row gutter={24} align="middle">
        <Col span={3} className="title" style={{ margin: 0 }}>
          Select to Apply Filters:
        </Col>
        <Col span={7}>
          <Form.Item
            name="policy_uuid"
            label="Policy"
            className="label"
            labelAlign="left"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: '0px' }}
          >
            <Select placeholder="Product - Policy Number - Status" onChange={updateParams} options={policies?.map((item: any) => {
              return {
                label: item?.product + ' - ' + item?.policy_number + ' - ' + `${item?.expired ? 'Expired' : 'Active'}`,
                value: item?.uuid
              }
            })} />
          </Form.Item>
          <span
            className='cta'
            style={{ fontSize: '12px', lineHeight: '16px' }}
            onClick={() => {
              if (params?.policy_uuid) {
                form.setFieldsValue({ policy_uuid: undefined });
                updateParams()
              }
            }}>
            Clear policy
          </span>
        </Col>
        <Col span={7}>
          <Form.Item
            name="gender"
            label="Gender"
            className="label"
            labelAlign="left"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: '0px' }}
          >
            <Select placeholder="Select" onChange={updateParams} options={gender} />
          </Form.Item>
          <span
            className='cta'
            style={{ fontSize: '12px', lineHeight: '16px' }}
            onClick={() => {
              if (params?.gender) {
                form.setFieldsValue({ gender: undefined });
                updateParams()
              }
            }}>
            Clear gender
          </span>
        </Col>
        <Col span={7}>
          <Form.Item
            name="relation"
            label="Relation"
            className="label"
            labelAlign="left"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: '0px' }}
          >
            <Select mode='multiple' placeholder="Select Multiple" onChange={updateParams} options={relation} />
          </Form.Item>
          <span
            className='cta'
            style={{ fontSize: '12px', lineHeight: '16px' }}
            onClick={() => {
              if (params?.relation) {
                form.setFieldsValue({ relation: undefined });
                updateParams()
              }
            }}>
            Clear relation(s)
          </span>
        </Col>
      </Row>
    </Form>
    <div style={{ position: "absolute", left: "-3500px", top: 0, width: '1224px' }}>
      {data?.dashboard_data?.number?.claimed_submitted > 0 && <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        margin={40}
        scale={0.6}
        fileName={`Claims Report - ${clientName}.pdf`}
      >
        <Row gutter={[24, 24]} className='mb-48'>
          <Col span={8}>
            <div className="pdf-key">Organisation Name:</div>
            <div className="pdf-value">{clientName}</div>
          </Col>
          <Col span={8}>
            <div className="pdf-key">Report Date:</div>
            <div className="pdf-value">{moment().format('DD MMM YYYY')}</div>
          </Col>
          <Col span={8}></Col>

          {params?.policy_uuid && <><Col span={8}>
            <div className="pdf-key">Product:</div>
            <div className="pdf-value">{policies?.find((item: any) => item?.id === params?.policy_uuid)?.product}</div>
          </Col>
            <Col span={8}>
              <div className="pdf-key">Policy Number:</div>
              <div className="pdf-value">{policies?.find((item: any) => item?.id === params?.policy_uuid)?.policy_number}</div>
            </Col>
            <Col span={8}>
              <div className="pdf-key">Policy Status:</div>
              <div className="pdf-value">{policies?.find((item: any) => item?.id === params?.policy_uuid)?.expired ? 'Expired' : 'Active'}</div>
            </Col>
            <Col span={8}>
              <div className="pdf-key">Policy Start Date:</div>
              <div className="pdf-value">{moment(policies?.find((item: any) => item?.id === params?.policy_uuid)?.valid_till).subtract(365, 'd').format('DD MMM YYYY')}</div>
            </Col>
            <Col span={8}>
              <div className="pdf-key">Policy End Date:</div>
              <div className="pdf-value">{moment(policies?.find((item: any) => item?.id === params?.policy_uuid)?.valid_till).format('DD MMM YYYY')}</div>
            </Col></>}
          {(params?.gender || params?.relation) && <Col span={24}>
            <div className="pdf-key">Filtered By:</div>
            <Row gutter={8}>
              {params?.gender && <Col><div className="filter-tags">
                <span className="filter">{toTitleCase(params?.gender)}</span>
              </div></Col>}
              {params?.relation?.map((item: any) => {
                return <Col><div className="filter-tags">
                  <span className="filter">{toTitleCase(item)}</span>
                </div></Col>
              })}
            </Row>
          </Col>}

        </Row>
        <Row gutter={[24, 24]} className='mb-24 pdf-analytics'>
          <Col>
            <DashboardAnalytics
              title='Claims Submitted'
              primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.claimed_submitted)) || 0}
              secondaryValue={`${Math.round(data?.dashboard_data?.number?.claimed_submitted) || 0} Claims | Avg ${reformatAmountInK(Math.round(data?.dashboard_data?.amount?.average_claimed_amount) || 0)}`}
            />
          </Col>
          <Col>
            <DashboardAnalytics
              title='Claims Approved'
              primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.claimed_approved) || 0)}
              secondaryValue={`${Math.round(data?.dashboard_data?.number?.claimed_approved) || 0} Claims`}
            />
          </Col>
          <Col>
            <DashboardAnalytics
              title='Claims Rejected'
              primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.rejected_claims) || 0)}
              secondaryValue={`${Math.round(data?.dashboard_data?.number?.rejected_claims) || 0} Claims`}
            />
          </Col>
          <Col>
            <DashboardAnalytics
              title='Open Claims'
              primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.open_claims) || 0)}
              secondaryValue={`${Math.round(data?.dashboard_data?.number?.open_claims) || 0} Claims`}
            />
          </Col>
          <Col>
            <DashboardAnalytics
              title='Cashless Claims'
              primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.total_cashless_claims) || 0)}
              secondaryValue={`${Math.round(data?.dashboard_data?.number?.total_cashless_claims) || 0} Claims`}
            />
          </Col>
          <Col>
            <DashboardAnalytics
              title='Reimbursement Claims'
              primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.total_reimburement_claims) || 0)}
              secondaryValue={`${Math.round(data?.dashboard_data?.number?.total_reimburement_claims) || 0} Claims`}
            />
          </Col>
          <Col>
            <DashboardAnalytics
              title='Avg Reimbursement TAT'
              primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.average_reimburement_claims_tat) || 0)}
              secondaryValue={`${data?.dashboard_data?.number?.average_reimburement_claims_tat || 0} Days`}
            />
          </Col>
          <Col>
            <DashboardAnalytics
              title='Lives'
              primaryValue={`${data?.endorsement_calculator?.member_end_count || 0}`}
              secondaryValue={`At Inception ${data?.endorsement_calculator?.member_inception_count || 0}`}
            />
          </Col>
          <Col>
            <DashboardAnalytics
              title='Employees'
              primaryValue={`${data?.endorsement_calculator?.self_member_end_count || 0}`}
              secondaryValue={`At Inception ${data?.endorsement_calculator?.self_member_inception_count || 0}`}
            />
          </Col>
          {clientConfig?.enable_transactions && brokerConfig?.enable_transactions && <><Col>
            <DashboardAnalytics
              title='Premium Paid'
              primaryValue={`${reformatAmountInK(Math.round(data?.endorsement_calculator?.end_premium) || 0)}`}
              secondaryValue={`At Inception ${reformatAmountInK(Math.round(data?.endorsement_calculator?.inception_premium) || 0)}`}
            />
          </Col>
            <Col>
              <DashboardAnalytics
                title='Loss Ratio'
                primaryValue={`${data?.endorsement_calculator?.loss_ratio.toFixed(2) || 0}%`}
                secondaryValue={`Annualised ${typeof data?.endorsement_calculator?.annualised_loss_ratio === 'number' ? data?.endorsement_calculator?.annualised_loss_ratio.toFixed(2) : 0}%`}
              />
            </Col></>}
        </Row>

        <Loader loading={loading}>
          <Row gutter={[24, 24]} justify='space-around' align="top" className="analytics">
            {data?.tat_band_data?.length > 0 && <Col span={10}>
              <Card>
                <div className="title">Turnaround Time of Approved Claims</div>
                <ColumnPlot data={data?.tat_band_data?.map((item: any) => {
                  return {
                    Days: toTitleCase(item?.min_max_days),
                    Count: item?.count || 0
                  }
                })} xField="Days" yField="Count" />
              </Card>
            </Col>}
            {data?.status_wise_analysis?.count?.length > 0 && data?.status_wise_analysis?.sum?.length && <Col span={14}>
              <Card>
                <div className="title">Status wise Analysis of Total Claims</div>
                <DoubleMixPlot dataA={data?.status_wise_analysis?.count?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.type),
                    value: item?.value || 0
                  }
                })} dataB={data?.status_wise_analysis?.sum?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.type),
                    value: item?.value || 0
                  }
                })} contentA='Count' contentB='Amount' />
              </Card>
            </Col>}
            {data?.approved_claims_data?.length > 0 && <Col span={24}>
              <Card>
                <div className="title">Count and Amount of Claims made by Month</div>
                <ColumnLinePlot stacked={true} dataA={arrayTransform2Dto1D(data?.approved_claims_data?.map((item: any) => {
                  return [
                    {
                      Month: item?.month,
                      "Sum of Claimed Amount": item?.total_settled_amount || 0,
                      type: "Total Settled Amount"
                    },
                    {
                      Month: item?.month,
                      "Sum of Claimed Amount": item?.rejected_amount || 0,
                      type: "Total Rejected Amount"
                    },
                    {
                      Month: item?.month,
                      "Sum of Withdrawn Amount": item?.withdrawn_amount || 0,
                      type: "Total Withdrawn Amount"
                    },
                    {
                      Month: item?.month,
                      "Sum of Claimed Amount": item?.in_process_amount || 0,
                      type: "Total In Process Amount"
                    }
                  ]
                }))} dataB={data?.approved_claims_data?.map((item: any) => {
                  return {
                    Month: item?.month,
                    "Count of Claims Made": item?.count || 0
                  }
                })} xField="Month" yField={["Sum of Claimed Amount", "Count of Claims Made"]} />
              </Card>
            </Col>}
            {!params?.gender && data?.gender_wise_data?.total_lives?.length > 0 && <>
              {(data?.gender_wise_data?.approved_count?.length > 0 && data?.gender_wise_data?.approved_amount?.length > 0) ? <Col span={24}>
                <Card>
                  <div className="title">Gender wise Analysis</div>
                  <TripleMixPlot dataA={data?.gender_wise_data?.total_lives?.map((item: any) => {
                    return {
                      type: toTitleCase(item?.gender),
                      value: item?.count || 0
                    }
                  })} dataB={data?.gender_wise_data?.approved_count?.map((item: any) => {
                    return {
                      type: toTitleCase(item?.gender),
                      value: item?.count || 0
                    }
                  })} dataC={data?.gender_wise_data?.approved_amount?.map((item: any) => {
                    return {
                      type: toTitleCase(item?.gender),
                      value: item?.count || 0
                    }
                  })} contentA={'Life Count'} contentB={'Count of \n Approved Claims'} contentC={'Amount of \n Approved Claims'} />
                </Card>
              </Col> : <Col span={24}>
                <Card>
                  <div className="title">Gender wise Analysis</div>
                  <DonutPlot data={data?.gender_wise_data?.total_lives?.map((item: any) => {
                    return {
                      type: toTitleCase(item?.gender),
                      value: item?.value || 0
                    }
                  })} content='Life Count' />
                </Card>
              </Col>
              }
            </>}
            {params?.relation?.length !== 1 && data?.relationship_wise_data?.total_lives?.length > 0 && <>
              {(data?.relationship_wise_data?.approved_count?.length > 0 && data?.relationship_wise_data?.approved_amount?.length > 0) ? <Col span={24}>
                <Card>
                  <div className="title">Relationship wise Analysis</div>
                  <TripleMixPlot dataA={data?.relationship_wise_data?.total_lives?.map((item: any) => {
                    return {
                      type: toTitleCase(item?.relation),
                      value: item?.count || 0
                    }
                  })} dataB={data?.relationship_wise_data?.approved_count?.map((item: any) => {
                    return {
                      type: toTitleCase(item?.relation),
                      value: item?.count || 0
                    }
                  })} dataC={data?.relationship_wise_data?.approved_amount?.map((item: any) => {
                    return {
                      type: toTitleCase(item?.relation),
                      value: item?.value || 0
                    }
                  })} contentA={'Life Count'} contentB={'Count of \n Approved Claims'} contentC={'Amount of \n Approved Claims'} />
                </Card>
              </Col> : <Col span={24}>
                <Card>
                  <div className="title">Relationship wise Analysis</div>
                  <DonutPlot data={data?.relationship_wise_data?.total_lives?.map((item: any) => {
                    return {
                      type: toTitleCase(item?.relation),
                      value: item?.count || 0
                    }
                  })} content={'Life Count'} />
                </Card>
              </Col>
              }
            </>}
            {data?.age_band_wise_data && <>
              {(data?.age_band_wise_data?.approved_count?.length > 0 && data?.age_band_wise_data?.approved_amount?.length > 0) ? <Col span={24}>
                <Card>
                  <div className="title">Age band wise analysis</div>
                  <TripleMixPlot dataA={data?.age_band_wise_data?.total_lives?.map((item: any) => {
                    return {
                      type: item?.age_band,
                      value: item?.count || 0
                    }
                  })} dataB={data?.age_band_wise_data?.approved_count?.map((item: any) => {
                    return {
                      type: item?.age_band,
                      value: item?.value || 0
                    }
                  })} dataC={data?.age_band_wise_data?.approved_amount?.map((item: any) => {
                    return {
                      type: item?.age_band,
                      value: item?.value || 0
                    }
                  })} contentA={'Life Count'} contentB={'Count of \n Approved Claims'} contentC={'Amount of \n Approved Claims'} />
                </Card>
              </Col> : <Col span={24}>
                <Card>
                  <div className="title">Age Band wise Analysis</div>
                  <DonutPlot data={data?.age_band_wise_data?.total_lives?.map((item: any) => {
                    return {
                      type: item?.age_band,
                      value: item?.count || 0
                    }
                  })} content={'Life Count'} />
                </Card>
              </Col>
              }
            </>}
            {data?.treatment_wise_data?.length > 0 && <Col span={24}>
              <Card>
                <div className="title">Treatment wise Analysis of Approved Claims</div>
                <DoubleMixPlot dataA={data?.treatment_wise_data?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.treatment),
                    value: item?.count || 0
                  }
                })} dataB={data?.treatment_wise_data?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.treatment),
                    value: item?.total_claimed_amount || 0
                  }
                })} contentA='Count' contentB='Amount' />
              </Card>
            </Col>}
          </Row>
        </Loader>

      </PDFExport>}
    </div>
    {data?.dashboard_data?.number?.claimed_submitted > 0 && <>
      <Row gutter={[24, 24]} className='mb-24'>
        <Col>
          <DashboardAnalytics
            title='Claims Submitted'
            primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.claimed_submitted)) || 0}
            secondaryValue={`${Math.round(data?.dashboard_data?.number?.claimed_submitted) || 0} Claims | Avg ${reformatAmountInK(Math.round(data?.dashboard_data?.amount?.average_claimed_amount) || 0)}`}
          />
        </Col>
        <Col>
          <DashboardAnalytics
            title='Claims Approved'
            primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.claimed_approved) || 0)}
            secondaryValue={`${Math.round(data?.dashboard_data?.number?.claimed_approved) || 0} Claims`}
          />
        </Col>
        <Col>
          <DashboardAnalytics
            title='Claims Rejected'
            primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.rejected_claims) || 0)}
            secondaryValue={`${Math.round(data?.dashboard_data?.number?.rejected_claims) || 0} Claims`}
          />
        </Col>
        <Col>
          <DashboardAnalytics
            title='Open Claims'
            primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.open_claims) || 0)}
            secondaryValue={`${Math.round(data?.dashboard_data?.number?.open_claims) || 0} Claims`}
          />
        </Col>
        <Col>
          <DashboardAnalytics
            title='Cashless Claims'
            primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.total_cashless_claims) || 0)}
            secondaryValue={`${Math.round(data?.dashboard_data?.number?.total_cashless_claims) || 0} Claims`}
          />
        </Col>
        <Col>
          <DashboardAnalytics
            title='Reimbursement Claims'
            primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.total_reimburement_claims) || 0)}
            secondaryValue={`${Math.round(data?.dashboard_data?.number?.total_reimburement_claims) || 0} Claims`}
          />
        </Col>
        <Col>
          <DashboardAnalytics
            title='Avg Reimbursement TAT'
            // primaryValue={reformatAmountInK(Math.round(data?.dashboard_data?.amount?.average_reimburement_claims_tat) || 0)}
            primaryValue={`${data?.dashboard_data?.number?.average_reimburement_claims_tat || 0} Days`}
          />
        </Col>
        <Col>
          <DashboardAnalytics
            title='Lives'
            primaryValue={`${data?.endorsement_calculator?.member_end_count || 0}`}
            // secondaryValue={`At Inception ${data?.endorsement_calculator?.member_inception_count || 0}`}
          />
        </Col>
        <Col>
          <DashboardAnalytics
            title='Employees'
            primaryValue={`${data?.endorsement_calculator?.self_member_end_count || 0}`}
            // secondaryValue={`At Inception ${data?.endorsement_calculator?.self_member_inception_count || 0}`}
          />
        </Col>
        {clientConfig?.enable_transactions && brokerConfig?.enable_transactions && <><Col>
          <DashboardAnalytics
            title='Premium Paid'
            primaryValue={`${reformatAmountInK(Math.round(data?.endorsement_calculator?.end_premium) || 0)}`}
            // secondaryValue={`At Inception ${reformatAmountInK(Math.round(data?.endorsement_calculator?.inception_premium) || 0)}`}
          />
        </Col>
          <Col>
            <DashboardAnalytics
              title='Loss Ratio'
              primaryValue={`${data?.endorsement_calculator?.loss_ratio.toFixed(2) || 0}%`}
              secondaryValue={`Annualised ${typeof data?.endorsement_calculator?.annualised_loss_ratio === 'number' ? data?.endorsement_calculator?.annualised_loss_ratio.toFixed(2) : 0}%`}
            />
          </Col>
        </>}
      </Row>

      <Loader loading={loading}>
        <Row gutter={[24, 24]} justify='space-around' align="top" className="analytics">
          {data?.tat_band_data?.length > 0 && <Col span={10}>
            <Card>
              <div className="title">Turnaround Time of Approved Claims</div>
              <ColumnPlot data={data?.tat_band_data?.map((item: any) => {
                return {
                  Days: toTitleCase(item?.min_max_days),
                  Count: item?.count || 0
                }
              })} xField="Days" yField="Count" />
            </Card>
          </Col>}
          {data?.status_wise_analysis?.count?.length > 0 && data?.status_wise_analysis?.sum?.length && <Col span={14}>
            <Card>
              <div className="title">Status wise Analysis of Total Claims</div>
              <DoubleMixPlot dataA={data?.status_wise_analysis?.count?.map((item: any) => {
                return {
                  type: toTitleCase(item?.type),
                  value: item?.value || 0
                }
              })} dataB={data?.status_wise_analysis?.sum?.map((item: any) => {
                return {
                  type: toTitleCase(item?.type),
                  value: item?.value || 0
                }
              })} contentA='Count' contentB='Amount' />
            </Card>
          </Col>}
          {data?.approved_claims_data?.length > 0 && <Col span={24}>
            <Card>
              <div className="title">Count and Amount of Claims made by Month</div>
              <ColumnLinePlot stacked={true} dataA={arrayTransform2Dto1D(data?.approved_claims_data?.map((item: any) => {
                return [
                  {
                    Month: item?.month,
                    "Sum of Claimed Amount": item?.total_settled_amount || 0,
                    type: "Total Settled Amount"
                  },
                  {
                    Month: item?.month,
                    "Sum of Claimed Amount": item?.rejected_amount || 0,
                    type: "Total Rejected Amount"
                  },
                  {
                    Month: item?.month,
                    "Sum of Claimed Amount": item?.withdrawn_amount || 0,
                    type: "Total Withdrawn Amount"
                  },
                  {
                    Month: item?.month,
                    "Sum of Claimed Amount": item?.in_process_amount || 0,
                    type: "Total In Process Amount"
                  }
                ]
              }))} dataB={data?.approved_claims_data?.map((item: any) => {
                return {
                  Month: item?.month,
                  "Count of Claims Made": item?.count || 0
                }
              })} xField="Month" yField={["Sum of Claimed Amount", "Count of Claims Made"]} />
            </Card>
          </Col>}
          {!params?.gender && data?.gender_wise_data?.total_lives?.length > 0 && <>
            {(data?.gender_wise_data?.approved_count?.length > 0 && data?.gender_wise_data?.approved_amount?.length > 0) ? <Col span={24}>
              <Card>
                <div className="title">Gender wise Analysis</div>
                <TripleMixPlot dataA={data?.gender_wise_data?.total_lives?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.gender),
                    value: item?.count || 0
                  }
                })} dataB={data?.gender_wise_data?.approved_count?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.gender),
                    value: item?.count || 0
                  }
                })} dataC={data?.gender_wise_data?.approved_amount?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.gender),
                    value: item?.value || 0
                  }
                })} contentA={'Life Count'} contentB={'Count of \n Approved Claims'} contentC={'Amount of \n Approved Claims'} />
              </Card>
            </Col> : <Col span={24}>
              <Card>
                <div className="title">Gender wise Analysis</div>
                <DonutPlot data={data?.gender_wise_data?.total_lives?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.gender),
                    value: item?.value || 0
                  }
                })} content='Life Count' />
              </Card>
            </Col>
            }
          </>}
          {params?.relation?.length !== 1 && data?.relationship_wise_data?.total_lives?.length > 0 && <>
            {(data?.relationship_wise_data?.approved_count?.length > 0 && data?.relationship_wise_data?.approved_amount?.length > 0) ? <Col span={24}>
              <Card>
                <div className="title">Relationship wise Analysis</div>
                <TripleMixPlot dataA={data?.relationship_wise_data?.total_lives?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.relation),
                    value: item?.count || 0
                  }
                })} dataB={data?.relationship_wise_data?.approved_count?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.relation),
                    value: item?.count || 0
                  }
                })} dataC={data?.relationship_wise_data?.approved_amount?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.relation),
                    value: item?.value || 0
                  }
                })} contentA={'Life Count'} contentB={'Count of \n Approved Claims'} contentC={'Amount of \n Approved Claims'} />
              </Card>
            </Col> : <Col span={24}>
              <Card>
                <div className="title">Relationship wise Analysis</div>
                <DonutPlot data={data?.relationship_wise_data?.total_lives?.map((item: any) => {
                  return {
                    type: toTitleCase(item?.relation),
                    value: item?.count || 0
                  }
                })} content={'Life Count'} />
              </Card>
            </Col>
            }
          </>}
          {data?.age_band_wise_data && <>
            {(data?.age_band_wise_data?.approved_count?.length > 0 && data?.age_band_wise_data?.approved_amount?.length > 0) ? <Col span={24}>
              <Card>
                <div className="title">Age band wise analysis</div>
                <TripleMixPlot dataA={data?.age_band_wise_data?.total_lives?.map((item: any) => {
                  return {
                    type: item?.age_band,
                    value: item?.count || 0
                  }
                })} dataB={data?.age_band_wise_data?.approved_count?.map((item: any) => {
                  return {
                    type: item?.age_band,
                    value: item?.value || 0
                  }
                })} dataC={data?.age_band_wise_data?.approved_amount?.map((item: any) => {
                  return {
                    type: item?.age_band,
                    value: item?.value || 0
                  }
                })} contentA={'Life Count'} contentB={'Count of \n Approved Claims'} contentC={'Amount of \n Approved Claims'} />
              </Card>
            </Col> : <Col span={24}>
              <Card>
                <div className="title">Age Band wise Analysis</div>
                <DonutPlot data={data?.age_band_wise_data?.total_lives?.map((item: any) => {
                  return {
                    type: item?.age_band,
                    value: item?.count || 0
                  }
                })} content={'Life Count'} />
              </Card>
            </Col>
            }
          </>}
          {data?.treatment_wise_data?.length > 0 && <Col span={24}>
            <Card>
              <div className="title">Treatment wise Analysis of Approved Claims</div>
              <DoubleMixPlot dataA={data?.treatment_wise_data?.map((item: any) => {
                return {
                  type: toTitleCase(item?.treatment),
                  value: item?.count || 0
                }
              })} dataB={data?.treatment_wise_data?.map((item: any) => {
                return {
                  type: toTitleCase(item?.treatment),
                  value: item?.total_claimed_amount || 0
                }
              })} contentA='Count' contentB='Amount' />
            </Card>
          </Col>}
        </Row>
      </Loader>
    </>}

    {data?.dashboard_data?.number?.claimed_submitted === 0 && (
      <Alert message="No data found" type="warning" showIcon />
    )}
    {data?.length === 0 && (
      <Alert message="No data found" type="warning" showIcon />
    )}
  </>
};

export default ClaimsAnalytics;