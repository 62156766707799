import { Pie } from "@ant-design/charts"

interface Props{
  data: any;
  content: string;
  size?: number;
  type?: string;
}

const DonutPlot: React.FC<Props> = ({data, content, size, type}) => {
  const config = {
    appendPadding: 20,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.7,
    innerRadius: 0.65,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      autoRotate: false,
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '16px',
        },
        content: content,
      },
      title: undefined
    },
  };
  return <Pie {...config} />;
}

export default DonutPlot;
