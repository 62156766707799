import axios from 'axios';
import moment from 'moment';
import { errorNotification } from '../components/UI/Toast/Toast';

let LOCAL_ITEMS = ['accessToken', 'refreshToken'];

export const getParameterByName = (name: string, url: string) => {
  if (!url) url = window.location.href;
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /*eslint-enable */
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getParametersBySubstring = (name: string, url: string) => {
  if (!url) url = window.location.href;
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /*eslint-enable */
  let parts = window.location.search.slice(1).split('&');
  let result: string[] = [];
  parts.forEach((pair: any) => {
    pair = pair.split('=');
    if (pair[0].indexOf(name) > -1) {
      result.push(decodeURIComponent(pair[1]));
    }
  });
  return result.length ? result : null;
};

const STORAGE_TYPE = 'local';
export const setLocalData = (key: string, value: any) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }
  if (STORAGE_TYPE === 'local') {
    window.localStorage.setItem(key, value);
  } else if (STORAGE_TYPE === 'session') {
    window.sessionStorage.setItem(key, value);
  }
};

export const getLocalData = (key: any) => {
  if (STORAGE_TYPE === 'local') {
    let returnData: any = window.localStorage.getItem(key) || null;
    try {
      returnData = JSON.parse(returnData);
    } catch (e) {
      return returnData;
    }
    return returnData;
  } else if (STORAGE_TYPE === 'session') {
    let returnData: any = window.sessionStorage.getItem(key) || null;
    try {
      returnData = JSON.parse(returnData);
    } catch (e) {
      return returnData;
    }
    return returnData;
  }
};

export const deleteLocalData = (key: string) => {
  if (STORAGE_TYPE === 'local') {
    window.localStorage.removeItem(key);
  } else if (STORAGE_TYPE === 'session') {
    window.sessionStorage.removeItem(key);
  }
};

export const clearLocalData = () => {
  if (STORAGE_TYPE === 'local') {
    LOCAL_ITEMS.forEach((item) => {
      window.localStorage.removeItem(item);
    });
  } else if (STORAGE_TYPE === 'session') {
    LOCAL_ITEMS.forEach((item) => {
      window.sessionStorage.removeItem(item);
    });
  }
};

export const getAccessToken = () => {
  return getLocalData('accessToken') || null;
};

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const setAccessToken = (token: string) => {
  setLocalData('accessToken', token);
};

export const setRefreshToken = (token: string) => {
  setLocalData('refreshToken', token);
};

export const getRefreshToken = () => {
  return getLocalData('refreshToken') || null;
};

export const removeAxiosHeaders = () => {
  delete axios.defaults.headers.common['Authorization'];
};

export const setAxiosHeaders = (token = getAccessToken()) => {
  (function () {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  })();
};

export const getBreadcrumb = (queryParams: any) => {
  let url = window.location.pathname;
  let modified_url = url;
  Object.keys(queryParams).map((item: any, index: number) => {
    if (index !== 0) {
      modified_url = modified_url.replace('/' + queryParams[item], '');
    }
  });
  let breadcrumb = modified_url.split('/').filter((item) => item);
  breadcrumb = breadcrumb.map((item) => {
    return item.replace(/-/g, ' ');
  });
  const [active] = breadcrumb.slice(-1);
  breadcrumb = breadcrumb.slice(0, -1);
  const breadcrumb_string = breadcrumb.join(' / ');

  return { breadcrumb_string: breadcrumb_string, active: active };
};

export const findStrings = (obj: any) => {
  let strings: any = [];
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      strings.push(obj[key]);
    } else if (typeof obj[key] === 'object') {
      strings = strings.concat(findStrings(obj[key]));
    }
  }
  return strings;
}

export const toTitleCase = (text: string, preventCaseChange = false) => {
  if (typeof text !== 'string') return text;
  text = text.replaceAll('_', ' ');
  return text.replace(/\w\S*/g, function (txt) {
    if (preventCaseChange) return txt;
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const replaceSpace = (text: string, replaceWith: string) => {
  if (typeof text !== 'string') return text;
  text = text.replaceAll(' ', replaceWith);
  return text
}

export const amountToInt = (amount: any) => {
  if (!amount) return 0;
  if (typeof amount === 'number') return amount;
  amount = amount.replaceAll(',', '');
  return parseInt(amount);
};

export const uniqueFromList = (arr: any) => {
  var m = {} as any,
    newarr = [] as any;
  for (var i = 0; i < arr.length; i++) {
    var v = arr[i];
    if (!m[v]) {
      newarr.push(v);
      m[v] = true;
    }
  }
  return newarr;
};

export function toOrdinal(i: number) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export const errorHandler = (response: any) => {
  if (response?.status === 403) {
    errorNotification(response?.data?.detail);
    return;
  }
  if (response?.error instanceof Object) {
    return;
  }
  else if (response?.data?.error?.non_field_errors) {
    errorNotification(response?.data?.error?.non_field_errors?.[0]);
  }
  else if (typeof response?.data?.error === 'string') {
    errorNotification(response?.data?.error);
  } else if (typeof response?.error === 'string') {
    errorNotification(response?.error);
  } else if (response?.data?.error?.non_field_error) {
    errorNotification(response?.data?.error?.non_field_error?.[0]);
  } else if (response?.error?.non_field_errors) {
    errorNotification(response?.error?.non_field_errors?.[0]);
  } else if (response?.data?.error?.error_description) {
    errorNotification(response?.data?.error?.error_description);
  } else if (response?.data?.error?.self_details?.non_field_errors) {
    errorNotification(response?.data?.error?.self_details?.non_field_errors?.[0]);
  } else if (response?.detail) {
    errorNotification(response?.detail);
  } else if (response?.data?.detail) {
    errorNotification(response?.data?.detail);
  } else if (response?.data?.error?.products_list) {
    errorNotification('Provider cannot be added without assigning a product');
  } else if (typeof response?.message === 'string') {
    errorNotification(response?.message);
  }
};

export const cleanPayload = (payload: any) => {
  return Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null && v != ''));
};

export const nullIfEmpty = (payload: any, remove: boolean = false) => {
  let item = {} as any;
  for (const [key, value] of Object.entries(payload)) {
    if (value === null || value === undefined || value === '') {
      if (!remove) {
        item[key] = null;
      }
    } else {
      item[key] = value;
    }
  }
  return item;
};

function isObject(o: any) {
  return o === Object(o);
}

export const objectFromStringAsKey = (o: any, s: any) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (isObject(o) && k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export const onInputChangeHandler = (key: Array<string>, setErrorMessage: any, errorMessage: any) => {
  if (key.length === 1) {
    setErrorMessage({ ...errorMessage, [key[0]]: '' })
  } else if (key.length === 2) {
    setErrorMessage({ ...errorMessage, [key[0]]: { ...errorMessage[key[0]], [key[1]]: '' } })
  } else if (key.length === 3) {
    setErrorMessage({ ...errorMessage, [key[0]]: { ...errorMessage[key[0]], [key[1]]: { ...errorMessage[key[0]][key[1]], [key[2]]: '' } } })
  }
}

export const timeAgo = (from: any) => {
  const diffInMinutes = moment().diff(from, 'minutes');

  if (diffInMinutes < 60) {
    return `${diffInMinutes} mins ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  }

  const diffInDays = Math.floor(diffInMinutes / 1440);
  if (diffInDays === 1) {
    return 'yesterday';
  } else if (diffInDays < 7) {
    return `${diffInDays} days ago`;
  }

  const diffInWeeks = Math.floor(diffInMinutes / 10080);
  if (diffInWeeks < 4) {
    return `${diffInWeeks} weeks ago`;
  }

  const diffInMonths = Math.floor(diffInMinutes / 43200);
  return `${diffInMonths} months ago`;
}

export const extractObjects = (obj: any) => {
  let extractedObjects = [] as any;

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        extractedObjects = extractedObjects.concat(obj[key]);
      }
    }
  }

  return extractedObjects;
}

export const reformatAmountInK = (amount: any) => {
  if (typeof amount !== 'number') {
    return amount;
  }
  if (amount >= 1000000) {
    return '₹' + ((amount) / 1000000).toFixed(2) + 'M';
  } else
    if (amount >= 1000) {
      return '₹' + Math.round((amount) / 1000) + 'K';
    }
  return '₹' + amount;
};

export const graphColors = [
  '#61DDAA',
  '#FF4500',
  '#5B8FF9',
  '#F6BD16',
  '#65789B',
  '#7262FD',
  '#78D3F8',
  '#9661BC',
  '#F6903D',
  '#008685',
  '#2391FF',
]

export const getPinCodeInfo = (pinCode: string, setPinCodeInfo: any) => {
  if (RegExp('[1-9][0-9]{5}')?.test(pinCode)) {
    fetch(`https://api.postalpincode.in/pincode/${pinCode}`)
      .then((response) => response.json())
      .then((data) => {
        if (data[0]?.Status === "Success") {
          setPinCodeInfo({
            city: data[0]?.PostOffice[0]?.Block,
            state: data[0]?.PostOffice[0]?.State,
            loading: false
          })
        }
      })
  }
  else {
    return {
      city: '',
      state: ''
    }
  }
}
