import { Spin } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { VIEW_PERMISSION } from "../../constants/types";
import Unauthorized from "../../pages/Dashboard/Unauthorized";
// import Unauthorized from '../Unauthorized';

interface Props {
  loading?: boolean;
  screen?: string;
  children: any;
  isUnauth?: boolean;
}

const Loader: React.FC<Props> = ({ loading, screen, children, isUnauth }) => {
  const permissions = useSelector((state: any) => state.auth.permissions),
    location = useLocation(),
    isClaimsProcessor = location?.pathname?.includes('claims-processor') ? true : false;

  if (loading) {
    return (
      <div className="loader-div">
        <div className="loader">
          <Spin size="large"></Spin>
        </div>
      </div>
    );
  }

  if (isClaimsProcessor) {
    return children;
  }

  if ((screen && !permissions?.[screen]?.includes(VIEW_PERMISSION)) || (isUnauth)) {
    return <Unauthorized />
  }

  return children;
}

export default Loader;
