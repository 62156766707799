import { Badge, Image, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Router from '../../router/Router';
import './_loginlayout.scss';

const LoginLayout: React.FC = () => {
  const navigate = useNavigate(),
    logo = useSelector((state: any) => state.brokerconfig.logoUrl),
    primaryColor = useSelector((state: any) => state.brokerconfig.primaryColor);

  return (
    <div className="login-container">
      <Row justify="center">
        <Image src={logo} preview={false} className="logo" onClick={() => navigate('/')} />
      </Row>
      <Row justify="center">
        <div className="login-box">
          <Badge.Ribbon text="Admin portal" color={primaryColor} />
          <div className="login-content">
            <Router />
          </div>
        </div>
      </Row>
      {/* <Row justify="center">
            <div className="footer">© Safetynet Innovations Private Limited</div>
          </Row> */}
    </div>

  );
};

export default LoginLayout;
