import { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import Breadcrumbs from "../../../components/header/Breadcrumbs";
import TableCTAComponent from "../../../components/UI/Table/TableCTAComponent";
import api from "../../../api/api";
import { errorHandler } from "../../../utils/helper";
import { Col, Row } from "antd";
import OfferCard from "../../../components/UI/OfferCard/OfferCard";
import { TabButton } from "../../../components/UI/Button/Button";

const OffersAndPlansSection: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true),
    [activeTab, setActiveTab] = useState('ACTIVE'),
    [offers, setOffers] = useState<Array<any> | null>(null),
    [plans, setPlans] = useState<Array<any> | null>(null),
    tabChangeHandler = (tab: string) => {
      setActiveTab(tab);
    },
    tabs = {
      activeTab: activeTab,
      tabChangeHandler: tabChangeHandler,
      list: [
        {
          key: 'ACTIVE',
          text: 'Active',
          notification: false,
        },
        // {
        //   key: 'DISABLED',
        //   text: 'Disabled',
        //   notification: false,
        // },
      ],
    },
    reformatPlans = (plans: any) => {
      let output = [] as any
      for (let i = 0; i < plans?.length; i++) {
        let obj = output?.find((item: any) => item?.product_name === plans[i]?.product_name && item?.provider_name === plans[i]?.provider_name)
        if (!obj) {
          output = [...output, {
            product_name: plans[i]?.product_name,
            provider_name: plans[i]?.provider_name,
            plans: [{
              uuid: plans[i]?.uuid,
              plan_name: plans[i]?.plan_name,
              cost_price: plans[i]?.cost_price,
              selling_price: plans[i]?.selling_price,
              offer_selling_price: plans[i]?.offer_selling_price,
              is_active: plans[i]?.is_active,
            }]
          }]
        } else {
          output = output?.map((item: any) => {
            if (item?.product_name === plans[i]?.product_name && item?.provider_name === plans[i]?.provider_name) {
              return {
                ...item,
                plans: [...item.plans, {
                  uuid: plans[i]?.uuid,
                  plan_name: plans[i]?.plan_name,
                  cost_price: plans[i]?.cost_price,
                  selling_price: plans[i]?.selling_price,
                  offer_selling_price: plans[i]?.offer_selling_price,
                  is_active: plans[i]?.is_active,
                }]
              }
            } else return item
          })
        }
      }
      setPlans(output)
    },
    reformatOffers = (offers: any) => {
      let output = [] as any
      for (let i = 0; i < offers?.length; i++) {
        let obj = output?.find((item: any) => item?.provider_name === offers[i]?.provider_name)
        if (!obj) {
          output = [...output, {
            provider_name: offers[i]?.provider_name,
            offers: [{
              uuid: offers[i]?.uuid,
              offer_name: offers[i]?.name,
              is_active: offers[i]?.is_active,
            }]
          }]
        } else {
          output = output?.map((item: any) => {
            if (item?.provider_name === offers[i]?.provider_name) {
              return {
                ...item,
                offers: [...item.offers, {
                  uuid: offers[i]?.uuid,
                  offer_name: offers[i]?.name,
                  is_active: offers[i]?.is_active,
                }]
              }
            } else return item
          })
        }
      }
      setOffers(output)
    },
    fetchOffersAndPlans = () => {
      api.offers.brokerOfferPlanListing()
        .then((res) => {
          reformatOffers(res?.data?.offers);
          reformatPlans(res?.data?.plans);
          setLoading(false);
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
          setLoading(false);
        })
    };

  useEffect(() => {
    fetchOffersAndPlans()
  }, [])

  useEffect(() => {
    if (offers && plans) {
      setLoading(false)
    }
  }, [offers, plans])

  return <Loader loading={loading}>
    <Breadcrumbs />
    {tabs && (
        <div className="table-cta-tabs">
          {tabs?.list?.map((item: any, index: any) => {
            return (
              <TabButton
                key={index}
                active={tabs.activeTab === item.key}
                onClick={() => tabs.tabChangeHandler(item.key)}
              >
                {item.text}
              </TabButton>
            );
          })}
        </div>
      )}
    <Row className="mt-32" gutter={[24, 24]}>
      {plans?.map((item: any) => {
        return <Col span={8}>
          <OfferCard provider={item?.provider_name} product={item?.product_name} items={item?.plans?.map((x: any) => {
            return x?.plan_name
          })} type="Plan" />
        </Col>
      })}
      {offers?.map((item: any) => {
        return <Col span={8}>
          <OfferCard provider={item?.provider_name} items={item?.offers?.map((x: any) => {
            return x?.offer_name
          })} type="Offer" />
        </Col>
      })}
    </Row>
  </Loader>
}

export default OffersAndPlansSection;
