import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import api from '../../api/api';
import { authActions } from '../../store/auth';
import { errorHandler } from '../../utils/helper';
import './_navbar.scss';

interface Props {
  to?: string;
  children: any;
  group?: any;
  onClick?: any;
}

const NavbarItem: React.FC<Props> = ({ to, children, group, onClick }) => {
  const
    dispatch = useDispatch(),
    updateNotificationCount = () => {
      api.notification.getCount()
        .then((res) => {
          dispatch(
            authActions.setNotification({
              notification_count: res?.data?.notification_count,
            }));
        })
        .catch((err) => { errorHandler(err?.response) });
    };
  if (to) {
    return (
      <NavLink to={to} className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} onClick={updateNotificationCount}>
        <div className={`navbar-item ${group ? 'nav-group-item' : ''}`}>{children}</div>
      </NavLink>
    );
  }
  return (
    <div onClick={onClick} className={`navbar-item ${group ? 'nav-group-item' : ''}`}>{children}</div>
  )
};

export default NavbarItem;
