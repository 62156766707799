import { Pagination } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../../../constants/config';

interface Props {
  total: number | undefined;
  onPageChange: any;
  urlOffset?: number;
  urlLimit?: number;
}

const PaginationComponent: React.FC<Props> = ({ total, onPageChange, urlOffset, urlLimit }) => {
  const [searchParams, setSearchParams] = useSearchParams(),
    [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30', '40', '50']),
    [currentPage, setCurrentPage] = useState({
      pageNumber: Math.floor(
        parseInt(searchParams.get('offset') || '0') / parseInt(searchParams.get('limit') || DEFAULT_PAGE_SIZE) + 1
      ),
      pageSize: parseInt(searchParams.get('limit') || DEFAULT_PAGE_SIZE),
    });

  const pageChangeHandler = (page: number, pageSize: any) => {
    if (isNaN(pageSize) && total) {
      onPageChange({ limit: total, offset: 0 });
      setCurrentPage({ pageNumber: 1, pageSize: total });
      setPageSizeOptions(['10', '20', '30', '40', '50']);
    } else {
      onPageChange({ limit: pageSize, offset: (page - 1) * pageSize });
      setCurrentPage({ pageNumber: page, pageSize: pageSize });
      setPageSizeOptions(['10', '20', '30', '40', '50']);
    }
  };

  useEffect(() => {
    if ((urlOffset || urlOffset === 0) && urlLimit) {
      searchParams.set('limit', urlLimit.toString());
      searchParams.set('offset', urlOffset.toString());
      setSearchParams(searchParams, { replace: true });
      if (urlOffset === 0) {
        setCurrentPage({ pageNumber: 1, pageSize: urlLimit });
      } else {
        setCurrentPage({ pageNumber: urlOffset / urlLimit + 1, pageSize: urlLimit });
      }
    }
  }, [urlLimit, urlOffset]);

  return (
    <Pagination
      className="pagination"
      size="small"
      current={Math.floor(
        parseInt(searchParams.get('offset') || '0') / parseInt(searchParams.get('limit') || DEFAULT_PAGE_SIZE) + 1
      )}
      pageSizeOptions={pageSizeOptions}
      pageSize={currentPage.pageSize}
      locale={{ items_per_page: '' }}
      total={total}
      showSizeChanger
      onChange={(page, pageSize) => {
        pageChangeHandler(page, pageSize);
      }}
      onShowSizeChange={(current, size) => {
        pageChangeHandler(current, size);
      }}
    />
  );
};

export default PaginationComponent;
