import { EditOutlined } from '@ant-design/icons';
import { Popconfirm, Switch } from 'antd';
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import api from "../../../../api/api";
import EntityListing from "../../../../components/EntityListing/EntityListing";
import { successNotification } from '../../../../components/UI/Toast/Toast';
import { ActiveBreadcrump } from "../../../../components/header/Breadcrumbs";
import { DEFAULT_PAGE_SIZE } from '../../../../constants/config';
import { EDIT_PERMISSION } from "../../../../constants/types";
import { errorHandler } from '../../../../utils/helper';
import StepsToAvail from "./StepsToAvail";


const PlanListing: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams(),
    editPermission = useSelector((state: any) => state.auth.permissions)?.products?.includes(EDIT_PERMISSION),
    [visible, setVisible] = useState<string | null>(null),
    [params, setParams] = useState({
      limit: parseInt(searchParams.get('limit') || DEFAULT_PAGE_SIZE),
      offset: parseInt(searchParams.get('offset') || '0'),
    }) as any,
    listOfProducts = useSelector((state: any) => state.masterdata.listOfProducts),
    [curRecord, setCurRecord] = useState<any>({}),
    navigate = useNavigate(),
    { productid } = useParams(),
    [toggleLoading, setToggleLoading] = useState(false as any),
    toggle = (uuid: any, value: any) => {
      setToggleLoading(uuid);
      api.product.updateProductPlan(uuid, { is_active: value })
        .then((res) => {
          setToggleLoading(false);
          successNotification(res?.message);
          setParams((prev: any) => ({ ...prev }));
        })
        .catch((err) => {
          console.log(err);
          setToggleLoading(false);
          errorHandler(err?.response?.data);
        })
    },
    columns = [
      {
        title: "Plan name",
        dataIndex: "plan_name",
        key: "plan_name",
        render: (text: string, record: any) => {
          if (listOfProducts?.find((product: any) => product?.id == productid)?.avail_type === "coupon") {
            return <span className="cta" onClick={() => navigate(`${record?.uuid}`)}>{text}</span>
          } else {
            return <span>{text}</span>
          }
        }
      },
      {
        title: "Plan code",
        dataIndex: "plan_code",
        key: "plan_code",
      },
      {
        title: "Provider",
        dataIndex: "provider_name",
        key: "provider_name",
      },
      // {
      //   title: "Avail Type",
      //   dataIndex: "avail_type",
      //   key: "avail_type",
      // },
      {
        title: "Assigned Count",
        dataIndex: "assigned_coupon_count",
        key: "assigned_coupon_count",
      },
      {
        title: "Unassigned Count",
        dataIndex: "unassigned_coupon_count",
        key: "unassigned_coupon_count",
      },
      {
        title: "Status",
        key: "status",
        fixed: 'right',
        render: (text: string, record: any) => (
          <Popconfirm
            title={`Do you want to ${record?.is_active ? 'disable' : 'enable'} the offer?`}
            onConfirm={() => toggle(record?.uuid, !record?.is_active)}
            okText="Yes"
            cancelText="No"
          >
            <Switch
              // defaultChecked={record?.is_active}
              checked={record?.is_active}
              // onChange={(val) => {
              //   toggle(record?.uuid, val);
              // }}
              loading={toggleLoading === record?.uuid}
            />
          </Popconfirm>
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (text: string, record: any) => (
          <EditOutlined onClick={() => {
            navigate(`update/${record?.uuid}`);
            editHandler(record);
          }} />
        ),
      }
    ],
    editHandler = (record: any) => {
      setCurRecord(record);
      setVisible('edit');
    },
    routes = [
      {
        path: '/products/:productid',
        breadcrumb: ActiveBreadcrump,
        props: {
          value: listOfProducts?.find((product: any) => product?.id == productid)?.name,
          overRideRedirect: `/products/${productid}/plans`
        },
      },
    ],
    onCloseHandler = (refresh: boolean = false) => {
      setVisible(null);
      setCurRecord({})
      if (refresh) {
        setParams((prev: any) => {
          return { ...prev };
        });
      }
    }

  return (
    <>
      {visible && <StepsToAvail record={curRecord} onClose={onCloseHandler} visible={visible} />}
      <EntityListing
        setParams={setParams}
        params={params}
        urlOffset={params.offset}
        urlLimit={params.limit}
        searchKey={'search'}
        searchPlaceholder={'Search plans'}
        header={columns}
        routes={routes}
        tableDataApi={() => api.product.getProductPlans({ ...params, product_id: productid })}
        cta={{
          button: [
            {
              text: `Add plan`,
              editPermission: editPermission,
              onClick: () => {
                navigate('add')
                // setVisible('add');
              },
            }
          ],
        }}
      />
    </>
  )
}

export default PlanListing;


// UI
// 1. popconfirm on switch toggle

// BE
// 1. search on plan listing
// 2. categories on offer
// 3. is_active, validity, sub_image in plans
// 4. validity, redemption_type choices
// 5. send provider id in plan listing
// 6. Latest update pe reorder ho raha hai.

// transaction section

// is_new in offers and plans?