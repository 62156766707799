import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listOfProviders: [],
  listOfProducts: [],
  choices: [],
};

const masterdata = createSlice({
  name: 'masterdata',
  initialState,
  reducers: {
    setListOfProviders: (state, action) => {
      state.listOfProviders = action.payload;
    },
    setListOfProducts: (state, action) => {
      state.listOfProducts = action.payload;
    },
    setChoices: (state, action) => {
      state.choices = action.payload.choices;
    },
  },
});

export const masterdataActions = masterdata.actions;

export default masterdata.reducer;
