import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/Loader/Loader";
import TableCTAComponent from "../../../../../components/UI/Table/TableCTAComponent";
import Breadcrumbs, { ActiveBreadcrump } from "../../../../../components/header/Breadcrumbs";
import api from "../../../../../api/api";
import { useOutletContext, useParams } from "react-router-dom";
import { errorHandler } from "../../../../../utils/helper";
import { OffersAndPlansContextType } from "./OffersAndPlans";
import TableComponent from "../../../../../components/UI/Table/TableComponent";

const ListOffersAndPlans: React.FC = () => {
  const context = useOutletContext<OffersAndPlansContextType>(),
  database = context?.data?.plans?.filter((item: any) => item?.is_active)?.concat(context?.data?.offers?.filter((item: any) => item?.is_active));

  return <React.Fragment>
    <TableCTAComponent
    // search={{
    //   placeholder: "Search plans or offers",
    //   key: "search",
    //   size: "large"
    // }}
    summary={{
      totalCount: database?.length,
    }}
    cta={{
      button: [
        {
          text: 'Add or Update',
          to: 'update',
          type: 'primary'
        }
      ]
    }} />
    <TableComponent columns={context?.columns} dataSource={database} />
  </React.Fragment>
}

export default ListOffersAndPlans;
