import { Mix } from '@ant-design/plots';
import { reformatAmountInK } from '../../../utils/helper';

interface Props {
  dataA: any;
  dataB: any;
  dataC: any;
  contentA: string;
  contentB: string;
  contentC: string;
}

const TripleMixPlot: React.FC<Props> = ({ dataA, dataB, dataC, contentA, contentB, contentC }) => {
  const config = {
    tooltip: false as false,
    legend: {
      position: 'bottom',
    } as any,
    plots: [
      {
        type: 'pie' as 'pie',
        region: {
          start: {
            x: 0,
            y: 0,
          },
          end: {
            x: 0.33,
            y: 1,
          },
        },
        options: {
          data: dataA,
          angleField: 'value',
          colorField: 'type',
          radius: 0.7,
          innerRadius: 0.65,
          tooltip: {
            showMarkers: false,
          },
          label: {
            type: 'outer',
            offset: '40%',
          },
          interactions: [
            {
              type: 'element-active',
            },
            {
              type: 'element-selected',
            },
            {
              type: 'association-tooltip',
            },
            {
              type: 'association-highlight',
            },
          ],
          statistic: {
            content: {
              style: {
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '16px',
              },
              content: contentA,
            },
            title: undefined
          },
        },
      },
      {
        type: 'pie' as 'pie',
        region: {
          start: {
            x: 0.33,
            y: 0,
          },
          end: {
            x: 0.66,
            y: 1,
          },
        },
        options: {
          data: dataB,
          angleField: 'value',
          colorField: 'type',
          innerRadius: 0.65,
          radius: 0.7,
          tooltip: {
            showMarkers: false,
          },
          label: {
            type: 'outer',
            offset: '40%',
          },
          interactions: [
            {
              type: 'element-active',
            },
            {
              type: 'association-tooltip',
            },
            {
              type: 'association-highlight',
            },
          ],
          statistic: {
            content: {
              style: {
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '16px',
              },
              content: contentB,
            },
            title: undefined
          },
        },
      },
      {
        type: 'pie' as 'pie',
        region: {
          start: {
            x: 0.66,
            y: 0,
          },
          end: {
            x: 1,
            y: 1,
          },
        },
        options: {
          data: dataC,
          angleField: 'value',
          colorField: 'type',
          innerRadius: 0.65,
          radius: 0.7,
          tooltip: {
            showMarkers: false,
          },
          label: {
            type: 'outer',
            offset: '40%',
            formatter: (data: any) => `${reformatAmountInK(data.value)}`,
          },
          interactions: [
            {
              type: 'element-active',
            },
            {
              type: 'association-tooltip',
            },
            {
              type: 'association-highlight',
            },
          ],
          statistic: {
            content: {
              style: {
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '16px',
              },
              content: contentC,
            },
            title: undefined
          },
        },
      },
    ],
  };

  return <Mix {...config} />;
};

export default TripleMixPlot;

