import './_offer-card.scss';
import { Popover } from 'antd';

interface Props {
  type: 'Plan' | 'Offer';
  provider: string;
  product?: string;
  items: Array<any>;
}

const OfferCard: React.FC<Props> = ({ type, provider, product, items }) => {
  const popoverContent = <div>
    {items?.slice(1)?.map((item: any, index: any) => {
      return <li key={index}>
        {item}
      </li>
    })}
  </div>

  return <div className="offer-card">
    <div className="type">{type}</div>
    <div className="title">
      <span className="provider">{provider} {product ? ' - ' : ""}</span>
      {product && <span className="product">{product}</span>}
    </div>
    <div className="items">
      {items?.[0]}
      {items?.length - 1 > 0 ?
        <Popover content={popoverContent} title={`${type}s`}>
          <span className='count'>+{items?.length - 1}</span>
        </Popover>
        : ''}
    </div>
  </div>
}

export default OfferCard;
